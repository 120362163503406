import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
};

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },
  },
  extraReducers: (builder) => {},
});

export const { showCreateForm } = quoteSlice.actions;
export default quoteSlice.reducer;
