import React, { useState, useRef, useEffect } from "react";
import { FaGear, FaMoneyBill1Wave } from "react-icons/fa6";
import { FaCopy, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { setDelete } from "../../redux/features/deleteSlice";
import { useDispatch } from "react-redux";
import { showPaymentCreateForm } from "../../redux/features/expenseSlice";
import toast from "react-hot-toast";
import axios from "../../lib/axios";

const Options = ({ expense, edit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const copy = () => {
    axios
      .get(`/api/expense/copy/${expense.id}`)
      .then((data) => {
        console.log("data", data);
        if (data.status == 201) {
          navigate(`/expenses/${data.id}/edit`);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <div className="relative " ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="hover:bg-gray-400 p-2 rounded-md z-0"
      >
        <FaGear size={18} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-md z-50">
          {edit && (
            <button
              onClick={() => {
                dispatch(showPaymentCreateForm());
              }}
              className="flex px-4 py-1 text-gray-800 hover:bg-gray-200 w-full"
            >
              <FaMoneyBill1Wave className="ml-2 mt-1" size={16} />
              ثبت پرداخت
            </button>
          )}
          {!edit && (
            <Link
              to={`/expenses/${expense.id}/edit`}
              className="flex px-4 py-1 text-gray-800 hover:bg-gray-200"
            >
              <FaEdit className="ml-2 mt-1" />
              ویرایش
            </Link>
          )}
          <button
            onClick={() => copy()}
            className="flex px-4 py-1 text-gray-800 hover:bg-gray-200 w-full"
          >
            <FaCopy className="ml-2 mt-1" />
            کاپی
          </button>
          <button
            onClick={() =>
              dispatch(
                setDelete({
                  item: expense,
                  url: `/api/expense/${expense.id}/delete`,
                  navigateUrl: edit ? `/expenses` : "",
                })
              )
            }
            className="flex px-4 py-1 text-gray-800 hover:bg-gray-200 w-full"
          >
            <MdDelete className="ml-2 mt-1" size={18} />
            حذف
          </button>
        </div>
      )}
    </div>
  );
};

export default Options;
