import React, { useEffect, useState } from "react";
import axios from "../../lib/axios";
import { Link, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCheck, FaPlus } from "react-icons/fa6";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import Options from "../../components/quotes/Options";
import Delete from "../../components/Delete";
import { useSelector } from "react-redux";
import { toEn } from "../../lib/toEn";
import Select from "react-select";

export const statuses = [
  {
    color: `bg-black text-white`,
    title: "پیش نویس",
    value: 1,
  },
  {
    color: `bg-sky-400 text-white`,
    title: "ارسال شد",
    value: 2,
  },
  {
    color: `bg-green-500 text-white`,
    title: "تایید شد",
    value: 3,
  },
  {
    color: `bg-gray-500 text-white`,
    title: "رد شد",
    value: 4,
  },
  {
    color: `bg-red-500 text-white`,
    title: "لغو شد",
    value: 5,
  },
];

function QuoteEdit() {
  const { id } = useParams();
  const inputs = {
    id: "",
    name: "",
    description: "",
    qty: "",
    price: "",
    total: 0.0,
  };
  const [customer, setCustomer] = useState({});
  const [quote, setQuote] = useState({});
  const [items, setItems] = useState([inputs]);
  const [deleted, setDeleted] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const [statusColor, setStatusColor] = useState(`bg-black text-white`);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    axios
      .get(`/api/quote/${id}/edit`)
      .then((data) => {
        setCustomer(data.customer);
        if (data.items.length > 0) {
          setItems(data.items);
        } else {
          setItems([inputs]);
        }

        delete data.customer;
        delete data.items;
        setQuote(data);
        if (data.status) {
          setStatusColor(
            statuses.find((row) => row.value == data.status).color
          );
        }
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const updateQuote = (index, value) =>
    setQuote((prevValue) => ({ ...prevValue, [index]: value }));

  const editItem = (index, i, value) => {
    const updatedItems = items.map((item, j) => {
      if (j === index) {
        let total = 0.0;
        if (i === "qty") {
          total = value * item.price;
        } else if (i === "price") {
          total = value * item.qty;
        } else {
          total = item.total;
        }
        console.log(item.qty);
        return { ...item, [i]: value, total: total };
      } else {
        return item;
      }
    });

    setItems(updatedItems);
  };

  const addItem = () => {
    setItems([...items, inputs]);
  };

  const removeItem = (index) => {
    if (items[index].id) {
      setDeleted([...deleted, items[index].id]);
    }
    const filters = items.filter((item, i) => i !== index);
    setItems(filters);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(quote);
    setLoading(true);
    axios
      .put(`/api/quote/${id}/update`, { ...quote, items, deleted })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === 201) {
          const { quote } = data;
          setItems(quote.items);
          delete quote.customer;
          delete quote.items;
          setQuote(quote);
          setDeleted([]);
          toast.success("ویرایش موفقانه انجام شد.");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleItemChange = (index, e, i) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    console.log(value);
    if (regex.test(value)) {
      editItem(index, i, value);
    }
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4 ">
        <h3>نمبر فروش {id}</h3>
        <div className="flex gap-6">
          <Options quote={quote} edit={true} />
          <button onClick={addItem} className="flex hover:text-blue-400">
            <FaPlus className="mt-1 ml-1 font-bold" />
            اضافه کردن جنس
          </button>
          <button
            type="submit"
            form="submitForm"
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
            disabled={loading ? true : false}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
            ) : (
              <FaCheck className="mt-1 mx-2" />
            )}
            ذخیره
          </button>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex flex-col gap-4 text-gray-500">
          <Link
            className="text-sky-500 font-bold text-2xl hover:underline"
            to={`/customers/${1}/edit`}
          >
            {customer.name}
          </Link>
          <p>{customer.address1}</p>
          <p>
            نمبر تماس: <span dir="ltr">{customer.phone_number}</span>
          </p>
          <p>
            واتساب: <span dir="ltr">{customer.whatsapp_number}</span>
          </p>
          <p>
            ایمیل:{" "}
            <a
              href="mailto:azim@momtaz.af"
              target="_blank"
              className="text-blue-500 "
            >
              {customer.email}
            </a>
          </p>
        </div>
        <div className="text-gray-500">
          <table>
            <tr>
              <th className="bg-gray-50 border w-36">کوتیشن #</th>
              <td className="bg-gray-50 border w-60 p-2">{quote.id}</td>
            </tr>
            <tr>
              <th className="border w-36">تاریخ</th>
              <td className="border w-60 p-2">
                <input
                  name="created_date"
                  type="date"
                  form="submitForm"
                  required
                  onChange={(e) => updateQuote("created_date", e.target.value)}
                  value={quote.created_date}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>
            <tr>
              <th className="bg-gray-50  border w-36">تاریخ انقضا</th>
              <td className="bg-gray-50  border w-60 p-2">
                <input
                  name="expire_date"
                  type="date"
                  form="submitForm"
                  required
                  onChange={(e) => updateQuote("expire_date", e.target.value)}
                  value={quote.expire_date}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>
            <tr>
              <th className=" border w-36">حالت</th>
              <td className=" border w-60 p-2">
                <select
                  name="status"
                  required
                  form="submitForm"
                  onChange={(e) => {
                    updateQuote("status", e.target.value);
                    setStatusColor(
                      statuses.find((row) => row.value == e.target.value).color
                    );
                  }}
                  value={quote.status}
                  className={`ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full ${statusColor}`}
                >
                  {statuses.map((row, index) => (
                    <option key={index} value={row.value} className={row.color}>
                      {row.title}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <form
        action=""
        id="submitForm"
        onSubmit={handleSubmit}
        method="post"
      ></form>
      <div className="p-4 text-gray-500">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-start border">
                جنس <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-2 text-start border">توضیحات</th>
              <th className="px-4 py-2 text-start border">
                تعداد <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-2 text-start border">
                قیمت فی عدد <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-2 text-start border">قیمت مجموعی</th>
              <th className="px-4 py-2 text-start border"></th>
            </tr>
          </thead>
          <tbody>
            {items.map((row, index) => (
              <tr key={index} className="bg-gray-50">
                <td className=" px-4 py-2 border align-top ">
                  <input
                    name="name"
                    type="text"
                    form="submitForm"
                    value={row.name}
                    required
                    onChange={(e) => editItem(index, "name", e.target.value)}
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
                <td className="px-4 py-2 border align-top">
                  <textarea
                    name="description"
                    rows={2}
                    form="submitForm"
                    value={row.description}
                    onChange={(e) =>
                      editItem(index, "description", e.target.value)
                    }
                    className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>

                <td className=" px-4 py-2 border align-top">
                  <input
                    name="qty"
                    type="text"
                    form="submitForm"
                    step={0.01}
                    min={0.0}
                    pattern="^0*(\d+(\.\d{0,3})?|\.\d{1,3})$"
                    value={row.qty}
                    required
                    onChange={(e) => handleItemChange(index, e, "qty")}
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
                <td className=" px-4 py-2 border align-top">
                  <input
                    name="price"
                    type="text"
                    form="submitForm"
                    step={0.001}
                    min={0.0}
                    pattern="^0*(\d+(\.\d{0,3})?|\.\d{1,3})$"
                    required
                    value={row.price}
                    onChange={(e) => handleItemChange(index, e, "price")}
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
                <td className=" px-4 py-2 border align-top">
                  {row.total} {quote.currency}
                </td>
                <td className="px-2 py-2 border align-top">
                  {items.length > 1 && (
                    <button onClick={() => removeItem(index)}>
                      <IoClose size={28} className="text-blue-500" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-4 text-gray-600">
        <table>
          <tr>
            <th className="bg-gray-50 border w-36">واحد پولی</th>
            <td className="bg-gray-50 border w-60 p-2">
              <select
                name="currency"
                required
                form="submitForm"
                onChange={(e) => updateQuote("currency", e.target.value)}
                value={quote.currency}
                className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full bg-white"
              >
                <option value="دالر">دالر</option>
                <option value="افغانی">افغانی</option>
              </select>
            </td>
          </tr>
          <tr>
            <th className=" border w-36">مجموع</th>
            <td className=" border w-60 p-2">
              {quote.total} {quote.currency}
            </td>
          </tr>
        </table>
      </div>

      {deleteItem.id && <Delete />}
    </div>
  );
}

export default QuoteEdit;

// [11, 12, 13, 14, 15, 16]

// clients_id[4, 6, 7, 9, 890]1, 2, 3, 4
