import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../lib/axios";
import { FaPrint } from "react-icons/fa";

function DueBills() {
  const [dueBills, setDueBills] = useState([]);
  useEffect(() => {
    getDueBills();
  }, []);

  const getDueBills = () => {
    axios
      .get("/api/dashboard/due-bills")
      .then((data) => {
        setDueBills(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDownload = (row) => {
    axios
      .get(`/api/sale/${row.id}/download-pdf`, {
        responseType: "blob",
      })
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `bill-#${row.id}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };
  return (
    <div className=" border rounded-t-sm ">
      <p className="bg-gray-50 border-b rounded-t-sm px-2 py-1 mb-4" dir="rtl">
        سررسید فروشات
      </p>
      <div className="p-4">
        <table className="table-auto w-full border-t">
          <thead>
            <tr>
              <th className="px-4 py-2 text-start">حالت </th>
              <th className="px-4 py-2 text-start">فروش نمبر</th>
              <th className="px-4 py-2 text-start">سر رسید</th>
              <th className="px-4 py-2 text-start">مشتری</th>
              <th className="px-4 py-2 text-start">بیلانس</th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {dueBills &&
              dueBills.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-1 py-2 text-white">
                    {row.balance_amount > 0 ? (
                      <span className="bg-red-400 px-1 rounded-sm">باقی</span>
                    ) : (
                      <span className="bg-green-400 px-1 rounded-sm">
                        دریافت شد
                      </span>
                    )}
                  </td>
                  <td className="px-1 py-2 text-sky-500 hover:underline hover:text-sky-800">
                    <Link to={`/sales/${row.id}/edit`}>{row.id}</Link>
                  </td>
                  <td className="px-1 py-2">{row.due_date}</td>
                  <td className="px-1 py-2 text-sky-500 hover:underline hover:text-sky-800">
                    <Link to={`/customers/${row.cid}/edit`}>{row.name}</Link>
                  </td>
                  <td className="px-1 py-2">{row.balance_amount} دالر</td>
                  <td className="px-1 py-2">
                    <button onClick={() => handleDownload(row)}>
                      <FaPrint
                        className="text-gray-600 hover:text-blue-400"
                        size={18}
                      />
                    </button>
                  </td>
                  <td className="px-4 py-2"></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <p className="bg-gray-50 border-t rounded-t-sm px-2 py-1 text-center">
        {" "}
        <Link
          to="/sales"
          className="text-sky-500 hover:underline hover:text-sky-800"
        >
          لیست همه
        </Link>
      </p>
    </div>
  );
}

export default DueBills;
