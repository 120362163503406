import React, { useEffect, useState } from "react";
import { IoMdAdd, IoMdArrowBack } from "react-icons/io";
import axios from "../../lib/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCheck, FaPlus } from "react-icons/fa6";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import Select from "react-select";

import Options from "../../components/loans/Options";
import Delete from "../../components/Delete";
import { useSelector } from "react-redux";
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { toEn } from "../../lib/toEn";
import List from "../../components/loans/payments/List";
import Create from "../../components/loans/payments/Create";
import Edit from "../../components/loans/payments/Edit";

function LoanEdit() {
  const { id } = useParams();
  const inputs = {
    id: "",
    name: "",
    description: "",
    qtys: 0.0,
    qty_price: 0.0,
    total_price: 0.0,
    item_order: "",
  };
  const [lender, setLender] = useState({});
  const [loan, setLoan] = useState({});
  const [payments, setPayments] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const paymentCreateForm = useSelector(
    (state) => state.loan.paymentCreateForm
  );
  const paymentEditObj = useSelector((state) => state.loan.paymentEditObj);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!paymentCreateForm && !paymentEditObj && !deleteItem.id) {
      getData();
    }
    getAccounts();
  }, [id, paymentCreateForm, paymentEditObj, deleteItem]);

  const getData = () => {
    axios
      .get(`/api/loan/${id}/edit`)
      .then((data) => {
        setLender(data.lender);
        setPayments(data.payments);
        delete data.lender;
        delete data.payments;
        setLoan(data);
        console.log("wwwwwwwwwwwwwww", data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const getAccounts = () => {
    axios
      .get("/api/accounts/select2")
      .then((data) => {
        setAccounts(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateLoan = (index, value) =>
    setLoan((prevValue) => ({ ...prevValue, [index]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("ddddd", loan);
    axios
      .put(`/api/loan/${id}/update`, { ...loan, payments, deleted })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === 201) {
          const { loan } = data;

          setPayments(loan.payments);

          delete loan.lender;
          delete loan.payments;
          setLoan(loan);
          setDeleted([]);
          toast.success("ویرایش موفقانه انجام شد.");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleItemChange = (index, e, i) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    console.log(value);
    if (regex.test(value)) {
      // editItem(index, i, value);
    }
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4 ">
        <h3>نمبر قرضه {id}</h3>
        <div className="flex gap-6">
          <Options loan={loan} edit={true} />

          <button
            type="submit"
            form="submitForm"
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
            disabled={loading ? true : false}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
            ) : (
              <FaCheck className="mt-1 mx-2" />
            )}
            ذخیره
          </button>
        </div>
      </div>
      <div className="flex justify-between p-4 gap-8">
        <div className="flex flex-col gap-4 text-gray-500 w-full">
          <Link
            className="text-sky-500 font-bold text-2xl hover:underline"
            to={`/lenders/${1}/edit`}
          >
            {lender.name}
          </Link>
          <p>{lender.address1}</p>
          <p>
            نمبر تماس: <span dir="ltr">{lender.phone_number}</span>
          </p>
          <p>
            واتساب: <span dir="ltr">{lender.whatsapp_number}</span>
          </p>
          <p>
            ایمیل:{" "}
            <a
              href="mailto:azim@momtaz.af"
              target="_blank"
              className="text-blue-500 "
            >
              {lender.email}
            </a>
          </p>
          <div className="w-full">
            <hr className="my-8" />
            <List payments={payments} />
          </div>
        </div>
        <div className="text-gray-500">
          <table>
            <tr>
              <th className="bg-gray-50 border w-60">تاریخ </th>
              <td className="bg-gray-50 border w-60 p-2">
                <input
                  name="date"
                  type="date"
                  form="submitForm"
                  required
                  onChange={(e) => updateLoan("date", e.target.value)}
                  value={loan.date}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>
            <tr>
              <th className=" border w-60">مقدار</th>
              <td className=" border w-60 p-2">
                <input
                  name="amount"
                  type="text"
                  form="submitForm"
                  required
                  onChange={(e) => updateLoan("amount", e.target.value)}
                  value={loan.amount}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>

            <tr>
              <th className="bg-gray-50 border w-60">پرداختی</th>
              <td className="bg-gray-50 border w-60 p-2">
                <input
                  name="withdrawn"
                  type="text"
                  form="submitForm"
                  required
                  disabled
                  onChange={(e) => updateLoan("withdrawn", e.target.value)}
                  value={loan.withdrawn}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>

            <tr>
              <th className=" border w-60">بیلانس</th>
              <td className=" border w-60 p-2">
                <input
                  name="balance"
                  type="text"
                  form="submitForm"
                  required
                  disabled
                  onChange={(e) => updateLoan("balance", e.target.value)}
                  value={loan.balance}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>
            <tr>
              <th className="bg-gray-50 border w-60">نرخ ۱ دالر به افغانی</th>
              <td className="bg-gray-50 border w-60 p-2">
                <input
                  name="amount"
                  type="text"
                  form="submitForm"
                  required
                  onChange={(e) => updateLoan("amount", e.target.value)}
                  value={loan.currency_rate}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>
            <tr>
              <th className=" border w-60">به حساب</th>
              <td className=" border w-60 p-2">
                <Select
                  className="ring-1 ring-inset ring-gray-300"
                  placeholder="انتخاب"
                  required
                  onChange={(e) => {
                    updateLoan("account_id", e.value);
                  }}
                  value={accounts.find((row) => row.value == loan.account_id)}
                  options={accounts.filter((row) =>
                    row.use_for.includes("قروض")
                  )}
                />
              </td>
            </tr>
            <tr>
              <th className="bg-gray-50 border w-60"> نوت</th>
              <td className="bg-gray-50 border w-60 p-2">
                <textarea
                  name="note"
                  onChange={(e) => updateLoan("note", e.target.value)}
                  value={loan.note}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm "
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <form
        action=""
        id="submitForm"
        onSubmit={handleSubmit}
        method="post"
      ></form>
      <div className="p-4 text-gray-500"></div>

      {deleteItem.id && <Delete />}
      {paymentCreateForm && <Create loan={loan} />}
      {paymentEditObj && <Edit />}
    </div>
  );
}

export default LoanEdit;

// [11, 12, 13, 14, 15, 16]

// clients_id[4, 6, 7, 9, 890]1, 2, 3, 4
