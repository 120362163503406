import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { showCreateForm } from "../../redux/features/saleSlice";
import { FaCheck } from "react-icons/fa";

const CreateSale = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [customers, setCustomers] = useState([]);
  const inputs = { due_date: "", customer_id: "" };
  const [formData, setFormData] = useState(inputs);

  const navigate = useNavigate();

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = () => {
    axios
      .get("/api/customers/select2")
      .then((data) => {
        console.log(data);
        setCustomers(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .post("api/sale/create", { ...formData })
      .then((data) => {
        setLoading(false);
        toast.success("عملیات موفقانه انجام شد.");
        navigate(`/sales/${data.sale.id}/edit`);
        dispatch(showCreateForm());
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        console.log("reeees", error);
      });
  };
  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>اضافه کردن فروشات</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => dispatch(showCreateForm())}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createSaleForm"
            >
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تاریخ <span className="text-red-500">*</span>
                </label>
                <input
                  name="date"
                  type="date"
                  required
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      date: e.target.value,
                    }))
                  }
                  value={formData.date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  سر رسید <span className="text-red-500">*</span>
                </label>
                <input
                  name="due_date"
                  type="date"
                  required
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      due_date: e.target.value,
                    }))
                  }
                  value={formData.due_date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  مشتری <span className="text-red-500">*</span>
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  required
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      customer_id: e.value,
                    }))
                  }
                  options={customers}
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createSaleForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaCheck className="mt-1 mx-2" />
                )}
                ثبت گردد
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSale;
