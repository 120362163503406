import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  editObj: null,
  report: false,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },

    setTransactionObj: (state, { payload }) => {
      console.log("dddddsssdddddddddddddddd", payload);
      state.editObj = payload;
    },
    showReportForm: (state) => {
      state.report = !state.report;
    },
  },
  extraReducers: (builder) => {},
});

export const { showCreateForm, setTransactionObj, showReportForm } =
  transactionSlice.actions;
export default transactionSlice.reducer;
