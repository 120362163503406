import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Options from "../../components/distributes/Options";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../components/Delete";
import { showCreateForm } from "../../redux/features/preparationSlice";
import Select from "react-select";
import { debounce } from "lodash";
import Edit from "../../components/distributes/Edit";
import { showDistributeCreateForm } from "../../redux/features/distributeSlice";
import Payment from "../../components/distributes/Payment";

function DistributeList() {
  const [products, setProducts] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const editObj = useSelector((state) => state.distribute.editObj);
  const paymentObj = useSelector((state) => state.distribute.paymentObj);
  const createForm = useSelector((state) => state.distribute.createForm);
  const [categories, setCategories] = useState([
    { name: "همه", active: false, en: "all" },
    { name: " زیرکار", active: true, en: "inprocess" },
    { name: "ثبت گدام", active: false, en: "stocked" },
  ]);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState(10);
  const [url, setUrl] = useState(`/api/distributes`);
  const [pCategories, setPCategories] = useState([]);
  const [pCategory, setPCategory] = useState(null);
  const [codes, setCodes] = useState([]);
  const [code, setCode] = useState(null);
  const [sizes, setSizes] = useState([]);
  const [size, setSize] = useState(null);
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState(null);
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);
  const [status, setStatus] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getPCategories();
    getCodes();
    getColors();
    getSizes();
    getTeams();
  }, []);

  useEffect(() => {
    if (!deleteItem.id && !createForm && !editObj && !paymentObj) {
      getProducts();
    }
  }, [
    categories,
    deleteItem,
    records,
    url,
    pCategory,
    code,
    size,
    color,
    createForm,
    editObj,
    paymentObj,
    team,
    status,
  ]);

  const delayedSearch = debounce(() => {
    getProducts();
    console.log("workingg");
  }, 5000);

  useEffect(() => {
    let delaySearch;
    const handleSearchDelay = () => {
      delaySearch = setTimeout(() => {
        console.log(`Performing search for: ${search}`);
        getProducts();
      }, 800);
    };
    const handleKeyUp = () => {
      if (delaySearch) {
        clearTimeout(delaySearch);
      }
      handleSearchDelay();
    };
    handleKeyUp();
    return () => {
      clearTimeout(delaySearch);
    };
  }, [search]);

  const getPCategories = () => {
    axios.get(`/api/settings/product/category/select2`).then((data) => {
      setPCategories(data);
    });
  };
  const getCodes = () => {
    axios.get(`/api/settings/code/select2`).then((data) => {
      setCodes(data);
    });
  };
  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };
  const getSizes = () => {
    axios.get(`/api/settings/sizes/select2`).then((data) => {
      setSizes(data);
    });
  };

  const getTeams = () => {
    axios.get(`/api/settings/team/select2`).then((data) => {
      setTeams(data);
    });
  };

  const getProducts = () => {
    axios
      .post(url, {
        category: categories.find((row) => row.active === true).en,
        search,
        records,
        pCategory,
        code,
        color,
        size,
        team,
        status,
      })
      .then((data) => {
        setProducts(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCategory = (index) => {
    setCategories((prevCategories) => {
      const updatedCategories = prevCategories.map((row, i) => {
        if (index === i) {
          return { ...row, active: true };
        }
        return { ...row, active: false };
      });

      return updatedCategories;
    });
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row bg-slate-50 border justify-between py-2 px-4">
        <h3>بخش توزیع</h3>
        <div className="flex gap-6 ">
          {categories.map((row, index) => (
            <button
              key={index}
              onClick={() => updateCategory(index)}
              className={` rounded-sm  border border-blue-300 px-2 ${
                row.active
                  ? "bg-blue-300 text-gray-700"
                  : "text-blue-300 hover:text-blue-500 hover:border-blue-500 hover:bg-gray-100"
              }`}
              disabled={row.active ? true : false}
            >
              {row.name}
            </button>
          ))}
          <button
            onClick={() => {
              dispatch(showDistributeCreateForm());
            }}
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
          >
            <IoIosAddCircle className="ml-1" size={22} />
            اضافه کردن
          </button>
        </div>
      </div>
      <div className="p-4">
        <div className="grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-10 gap-4 mt-8">
          <select
            onChange={(e) => setRecords(e.target.value)}
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
          >
            <option className="p-0 m-0" value={10}>
              10
            </option>
            <option className="p-0 m-0" value={20}>
              20
            </option>
            <option className="p-0 m-0" value={50}>
              50
            </option>
            <option className="p-0 m-0" value={100}>
              100
            </option>
            <option className="p-0 m-0" value={500}>
              500
            </option>
          </select>
          <select
            onChange={(e) => setStatus(e.target.value)}
            className="ring-1 ring-inset ring-gray-300  mb-3 px-2 py-2 rounded-md"
          >
            <option className="p-0 m-0" value={null}>
              انتخاب
            </option>
            <option className="p-0 m-0" value={"debetor"}>
              باقیات
            </option>
            <option className="p-0 m-0" value={"balance"}>
              تسویه شده ها
            </option>
          </select>
          <Select
            placeholder="تیم"
            required
            onChange={(e) => setTeam(e ? e.value : null)}
            isClearable={true}
            options={teams}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "0.2rem", // Adjust the padding value as needed
              }),
            }}
          />
          <Select
            placeholder="نوع محصول"
            required
            onChange={(e) => setPCategory(e ? e.value : null)}
            isClearable={true}
            options={pCategories}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "0.2rem", // Adjust the padding value as needed
              }),
            }}
          />
          <Select
            placeholder="کود"
            required
            onChange={(e) => setCode(e ? e.value : null)}
            isClearable={true}
            options={codes}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "0.2rem", // Adjust the padding value as needed
              }),
            }}
          />
          <Select
            placeholder="رنگ"
            required
            onChange={(e) => setColor(e ? e.value : null)}
            isClearable={true}
            options={colors}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "0.2rem", // Adjust the padding value as needed
              }),
            }}
          />
          <Select
            placeholder="سایز"
            required
            onChange={(e) => setSize(e ? e.value : null)}
            isClearable={true}
            options={sizes}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "0.2rem", // Adjust the padding value as needed
              }),
            }}
          />

          <input
            type="text"
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
            value={search}
            placeholder="جستجو..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr className="border-y">
              <th className="px-4 py-2 text-start">آی دی</th>
              <th className="px-4 py-2 text-start">گروپ (تیم)</th>
              <th className="px-4 py-2 text-start">نمبر تهیه</th>
              <th className="px-4 py-2 text-start">#کد</th>
              <th className="px-4 py-2 text-start">نوع محصول</th>
              <th className="px-4 py-2 text-start">رنگ</th>
              <th className="px-4 py-2 text-start">سایز</th>
              <th className="px-4 py-2 text-start">تعداد</th>
              <th className="px-4 py-2 text-start">تاریخ توزیع</th>
              <th className="px-4 py-2 text-start">تاریخ دریافت</th>
              <th className="px-4 py-2 text-start">از بابت فیصدی خیاط </th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {products?.data &&
              products?.data.map((row, index) => {
                const isPaid =
                  row.type === "دالر"
                    ? row.total_payment - row.paid_tailor_fee_amount
                    : row.total_payment -
                      row.paid_tailor_fee_amount / row.currency_rate;
                return (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    } border`}
                  >
                    <td className="px-4 py-2">{row.id}</td>
                    <td className="px-4 py-2">{row.tname}</td>
                    <td className="px-4 py-2">
                      <Link
                        className="text-sky-500 hover:text-sky-800"
                        to={`/preparations/${row.preparation_id}/edit`}
                      >
                        {row.preparation_id}
                      </Link>
                    </td>
                    <td className="px-4 py-2">{row.code}</td>

                    <td className="px-4 py-2">{row.pname}</td>
                    <td className="px-4 py-2">{row.cname}</td>
                    <td className="px-4 py-2">{row.sname}</td>
                    <td className="px-4 py-2">{row.qtys}</td>
                    <td className="px-4 py-2">{row.distributed_date}</td>
                    <td className="px-4 py-2">{row.received_date}</td>
                    <td className="px-4 py-2">
                      <span
                        className={`${
                          isPaid == 0 ? "bg-green-400 " : "bg-red-400"
                        } text-white  px-2 rounded-sm`}
                      >
                        {isPaid == 0 ? "پرداخت شد" : "باقی"}
                      </span>
                    </td>
                    <td className="px-4 py-2">
                      <Options distribute={row} />{" "}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex gap-4 justify-end p-4">
          {products.links &&
            products.links.length > 3 &&
            products.links.map((row, index) => (
              <button
                key={index}
                disabled={row.active || row.url === null}
                onClick={() => setUrl(row.url)}
                className={`border py-1 px-3 ${
                  row.active ? "bg-blue-500 text-white" : "hover:bg-gray-300"
                }`}
              >
                <>{row.label}</>
              </button>
            ))}
          {paymentObj && <Payment />}
          {editObj && <Edit />}
          {deleteItem.id && <Delete />}
        </div>
      </div>
    </div>
  );
}

export default DistributeList;
