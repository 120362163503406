import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Message from "./message";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoCloseCircle } from "react-icons/io5";
import { setImg } from "../../redux/features/imgSlice";

const DocPreview = () => {
  const dispatch = useDispatch();
  const url = useSelector((state) => state.img.url);

  const navigate = useNavigate();

  return (
    <>
      <div className="fixed inset-0 opacity-25 bg-black"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 max-w-5xl ">
          <div className="relative bg-white shadow dark:bg-gray-700">
            <button className="absolute" onClick={() => dispatch(setImg(""))}>
              <IoCloseCircle
                className=" text-red-500 hover:text-red-700"
                size={30}
              />
            </button>
            <img src={url} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DocPreview;
