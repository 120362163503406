import React, { useEffect, useState } from "react";
import { IoMdAdd, IoMdArrowBack } from "react-icons/io";
import axios from "../../../lib/axios";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast from "react-hot-toast";
import { digitsFaToEn } from "@persian-tools/persian-tools";

const labels = {
  logo: "لوگو",
  address_1: "آدرس۱",
  address_2: "آدرس۲",
  email: "ایمیل",
  phone_number: "نمبر تماس",
  whatsapp_number: "واتساب نمبر",
  terms: "مقررات",
};
function Setting() {
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = () => {
    axios
      .get(`/api/settings/setting`)
      .then((data) => {
        console.log(data);
        setSettings(data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const updateSettings = (index, value) =>
    setSettings((prev) => ({ ...prev, [index]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(settings).forEach((key) => {
      formData.append(key, settings[key]);
    });
    setLoading(true);
    console.log("ddddd", settings);
    axios
      .post(`/api/settings/setting`, settings, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setSettings(data);
        toast.success("یک ریکارت موفقانه اضافه گردید.");
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleImageChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      // reader.onload = () => {
      //   setPreviewImage(reader.result);
      // };

      reader.readAsDataURL(file);
      updateSettings(index, file);
      // setImgChanged(true);
    }
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>تنظیمات</h3>
        <Link
          to="/"
          className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
        >
          بازگشت
          <IoMdArrowBack className="ml-1" size={22} />
        </Link>
      </div>
      <div className="p-4">
        <form action="#" method="post" onSubmit={handleSubmit}>
          <div className="flex gap-8">
            <label htmlFor="details" className="w-1/3 md:w-1/5">
              {labels["logo"]} <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              name={"logo"}
              onChange={(e) => handleImageChange(e, "logo")}
              className="  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
            {settings.logo && (
              <img
                src={`${process.env.REACT_APP_BASE_URL}/storage/${settings.logo}`}
                width={200}
                alt=""
              />
            )}
          </div>
          <div className="flex gap-8">
            <label htmlFor="details" className="w-1/3 md:w-1/5">
              {labels["email"]} <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              name={"email"}
              onChange={(e) => updateSettings("email", e.target.value)}
              value={settings.email}
              required
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="details" className="w-1/3 md:w-1/5">
              {labels["address_1"]} <span className="text-red-500">*</span>
            </label>
            <input
              type="address_1"
              name={"address_1"}
              onChange={(e) => updateSettings("address_1", e.target.value)}
              value={settings.address_1}
              required
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="details" className="w-1/3 md:w-1/5">
              {labels["address_2"]}
            </label>
            <input
              type="address_2"
              name={"address_2"}
              onChange={(e) => updateSettings("address_2", e.target.value)}
              value={settings.address_2}
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="details" className="w-1/3 md:w-1/5">
              {labels["phone_number"]} <span className="text-red-500">*</span>
            </label>
            <input
              type="phone_number"
              name={"phone_number"}
              onChange={(e) => updateSettings("phone_number", e.target.value)}
              value={settings.phone_number}
              required
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="details" className="w-1/3 md:w-1/5">
              {labels["whatsapp_number"]}{" "}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="whatsapp_number"
              name={"whatsapp_number"}
              onChange={(e) =>
                updateSettings("whatsapp_number", e.target.value)
              }
              value={settings.whatsapp_number}
              required
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>

          <div className="flex gap-8">
            <label htmlFor="details" className="w-1/3 md:w-1/5">
              {labels["terms"]}
            </label>
            <textarea
              name={settings.terms}
              onChange={(e) => updateSettings("terms", e.target.value)}
              value={settings.terms}
              rows={9}
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-3/5"
            />
          </div>

          <div className="flex gap-8">
            <div className="w-1/3 md:w-1/5"></div>
            <div className="w-2/3 md:w-2/5">
              <button
                className="flex gap-4 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700 w-full justify-center"
                type="submit"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
                ) : (
                  <IoMdAdd className="mt-1" />
                )}
                ثبت تامین کننده
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Setting;
