import React, { useState } from "react";
import { IoMdAdd, IoMdArrowBack } from "react-icons/io";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast from "react-hot-toast";
import { digitsFaToEn } from "@persian-tools/persian-tools";

function LenderCreate() {
  const inputs = {
    name: "",
    contact_name: "",
    phone_number: "",
    whatsapp_number: "",
    email: "",
    address1: "",
    address2: "",
    detail: "",
  };
  const [lender, setLender] = useState(inputs);

  const [loading, setLoading] = useState(false);

  const updateLender = (index, value) =>
    setLender((prv) => ({ ...prv, [index]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(lender);
    axios
      .post(`/api/lender/create`, {
        ...lender,
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === 201) {
          toast.success("یک ریکارت موفقانه اضافه گردید.");
          setLender(inputs);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>اضافه کردن قرض دهنده</h3>
        <Link
          to="/lenders"
          className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
        >
          بازگشت
          <IoMdArrowBack className="ml-1" size={22} />
        </Link>
      </div>
      <div className="p-4">
        <form action="#" method="post" onSubmit={handleSubmit}>
          <div className="flex gap-8">
            <label htmlFor="name" className="w-1/3 md:w-1/5">
              نام <span className="text-red-500">*</span>
            </label>
            <input
              name="name"
              required
              onChange={(e) => updateLender("name", e.target.value)}
              value={lender.name}
              placeholder="احمد فروغ"
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>

          <div className="flex gap-8">
            <label htmlFor="phone_number" className="w-1/3 md:w-1/5">
              نمبر تماس <span className="text-red-500">*</span>
            </label>
            <input
              name="phone_number"
              required
              type="tel"
              // onKeyDown={handleKeyDown}
              onChange={(e) =>
                updateLender("phone_number", digitsFaToEn(e.target.value))
              }
              value={lender.phone_number}
              pattern="(\+[0-9]*)?[0-9]*"
              title="لطفن نمبر درست را وارید کنید."
              placeholder="+93XXXXXXXXX"
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="whatsapp_number" className="w-1/3 md:w-1/5">
              نمبر واتساب
            </label>
            <input
              name="whatsapp_number"
              type="tel"
              onChange={(e) =>
                updateLender("whatsapp_number", digitsFaToEn(e.target.value))
              }
              value={lender.whatsapp_number}
              pattern="(\+[0-9]*)?[0-9]*"
              title="لطفن نمبر درست را وارید کنید."
              placeholder="+93XXXXXXXXX"
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="email" className="w-1/3 md:w-1/5">
              ایمیل
            </label>
            <input
              name="name"
              type="email"
              dir="ltr"
              placeholder="mahmoodi@gmail.com"
              onChange={(e) => updateLender("email", e.target.value)}
              value={lender.email}
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>

          <div className="flex gap-8">
            <label htmlFor="address1" className="w-1/3 md:w-1/5">
              آدرس ۱ <span className="text-red-500">*</span>
            </label>
            <textarea
              name="address1"
              required
              onChange={(e) => updateLender("address1", e.target.value)}
              value={lender.address1}
              rows={2}
              placeholder="آدرس..."
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="address2" className="w-1/3 md:w-1/5">
              آدرس 2
            </label>
            <textarea
              name="address2"
              onChange={(e) => updateLender("address2", e.target.value)}
              value={lender.address2}
              rows={2}
              placeholder="آدرس..."
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/5"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="details" className="w-1/3 md:w-1/5">
              بیشتر
            </label>
            <textarea
              name="details"
              onChange={(e) => updateLender("detail", e.target.value)}
              value={lender.detail}
              rows={9}
              placeholder="بیشتر..."
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-3/5"
            />
          </div>

          <div className="flex gap-8">
            <div className="w-1/3 md:w-1/5"></div>
            <div className="w-2/3 md:w-2/5">
              <button
                className="flex gap-4 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700 w-full justify-center"
                type="submit"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
                ) : (
                  <IoMdAdd className="mt-1" />
                )}
                ثبت قرض دهنده
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LenderCreate;
