import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../lib/axios";

function LastExpenses() {
  const [expenses, setExpenses] = useState([]);
  useEffect(() => {
    getExpenses();
  }, []);

  const getExpenses = () => {
    axios
      .get("/api/dashboard/last-expenses")
      .then((data) => {
        setExpenses(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className=" border rounded-t-sm mt-8">
      <p className="bg-gray-50 border-b rounded-t-sm px-2 py-1 mb-4" dir="rtl">
        آخرین مصارفات
      </p>
      <div className="p-4">
        <table className="table-auto w-full border-t">
          <thead>
            <tr>
              <th className="px-4 py-2 text-start">کتگوری </th>
              <th className="px-4 py-2 text-start">مصارف نمبر</th>
              <th className="px-4 py-2 text-start">عنوان</th>
              <th className="px-4 py-2 text-start">مقدار</th>
              <th className="px-4 py-2 text-start">صندوق</th>
            </tr>
          </thead>
          <tbody>
            {expenses &&
              expenses.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-1 py-2">{row.category.name}</td>
                  <td className="px-1 py-2 text-sky-500 hover:underline hover:text-sky-800">
                    <Link to={`/expenses/${row.id}/edit`}>{row.id}</Link>
                  </td>
                  <td className="px-1 py-2">{row.title}</td>

                  <td className="px-1 py-2">
                    {row.total} {row.account.type}
                  </td>
                  <td className="px-1 py-2">{row.account.name}</td>

                  <td className="px-4 py-2"></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <p className="bg-gray-50 border-t rounded-t-sm px-2 py-1 text-center">
        {" "}
        <Link
          to="/expenses"
          className="text-sky-500 hover:underline hover:text-sky-800"
        >
          لیست همه
        </Link>
      </p>
    </div>
  );
}

export default LastExpenses;
