import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import axios from "../../lib/axios";
import { Link, useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import Message from "../../components/Message";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../redux/features/message";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function UserEdit() {
  const { id } = useParams();
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    roleName: "",
  });
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const status = useSelector((state) => state.message.status);
  const dispatch = useDispatch();

  useEffect(() => {
    getRoles();
    getUser();
  }, [id]);

  const getUser = () => {
    axios
      .get(`/api/user/${id}`)
      .then(({ data }) => {
        setUser((prevUser) => ({
          prevUser,
          name: data.name,
          email: data.email,
          roleName: data.role ? data.role.name : "",
        }));
        console.log(user);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };
  const getRoles = () => {
    axios
      .get(`/api/roles`)
      .then(({ data }) => {
        setRoles(data);

        console.log(data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const updateUser = (index, value) =>
    setUser((prevUser) => ({ ...prevUser, [index]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .put(`/api/user/${id}/update`, {
        ...user,
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === 201) {
          toast.success("یک ریکارت موفقانه ویرایش گردید.");
          //   dispatch(
          //     setMessage({
          //       status: data.status,
          //       message: "یک ریکارت موفقانه ویرایش گردید.",
          //     })
          //   );
        } else {
          toast.error(data.message);
          //   dispatch(setMessage({ status: data.status, message: data.message }));
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        // dispatch(
        //   setMessage({
        //     status: 400,
        //     message: error.message,
        //   })
        // );
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>ویرایش کاربر</h3>
        <Link
          to="/users"
          className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
        >
          بازگشت
          <IoMdArrowBack className="ml-1" size={22} />
        </Link>
      </div>
      <div className="p-4">
        <form action="#" method="post" onSubmit={handleSubmit}>
          {status && <Message />}
          <div className="flex gap-8">
            <label htmlFor="name" className="w-1/3 md:w-1/4">
              نام <span className="text-red-500">*</span>
            </label>
            <input
              name="name"
              required
              onChange={(e) => updateUser("name", e.target.value)}
              value={user.name}
              placeholder="حامد محمودی"
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/4"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="name" className="w-1/3 md:w-1/4">
              ایمیل <span className="text-red-500">*</span>
            </label>
            <input
              name="name"
              type="email"
              placeholder="mahmoodi@gmail.com"
              required
              onChange={(e) => updateUser("email", e.target.value)}
              value={user.email}
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/4"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="name" className="w-1/3 md:w-1/4">
              {" "}
              گذرواژه
            </label>
            <input
              name="name"
              onChange={(e) => updateUser("password", e.target.value)}
              value={user.password}
              placeholder='5(2"7Gm2xV'
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="رمز عبور باید حداقل دارای یک رقم، یک حرف کوچک و یک حرف بزرگ و حداقل 8 کاراکتر باشد."
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/4"
            />
          </div>
          <div className="flex gap-8">
            <label htmlFor="name" className="w-1/3 md:w-1/4">
              {" "}
              وظیفه
            </label>
            <select
              value={user.roleId}
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3 md:w-2/4"
              onChange={(e) => updateUser("roleName", e.target.value)}
            >
              <option value="">انتخاب</option>
              {roles &&
                roles.map((row, index) => (
                  <option
                    key={index}
                    value={row.name}
                    selected={row.name === user.roleName ? true : false}
                  >
                    {row.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="flex gap-8">
            <div className="w-1/3 md:w-1/4"></div>
            <div className="w-2/3 md:w-2/4">
              <button
                className="flex gap-4 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700 w-full justify-center"
                type="submit"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
                ) : (
                  <FaEdit className="mt-1" />
                )}
                ثبت ویرایش
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserEdit;
