import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  paymentCreateForm: false,
  paymentCreateSaleId: null,
  paymentEditObj: null,
  url: "",
  report: false,
  paymentReport: false,
};

export const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },
    showReportForm: (state) => {
      state.report = !state.report;
    },
    showReportPaymentForm: (state) => {
      state.paymentReport = !state.paymentReport;
    },
    showPaymentCreateForm: (state) => {
      state.paymentCreateForm = !state.paymentCreateForm;
    },
    setPaymentSaleId: (state, { payload }) => {
      state.paymentCreateSaleId = payload;
      console.log("paaaayload", state.paymentCreateSaleId);
    },
    setPaymentObj: (state, { payload }) => {
      console.log("dorking");
      state.paymentEditObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  showCreateForm,
  showPaymentCreateForm,
  setPaymentObj,
  setPaymentSaleId,
  showReportForm,
  showReportPaymentForm,
} = saleSlice.actions;
export default saleSlice.reducer;
