import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  setPermissions,
  setToken,
  setUser,
} from "../../redux/features/authSlice";
import toast from "react-hot-toast";
import axios from "../../lib/axios";
import { GoSignIn } from "react-icons/go";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logo, setLogo] = useState("");
  // const [message, setMessage] = useState({
  //   status: null,
  //   message: "",
  // });
  useEffect(() => {
    getLogo();
  }, []);

  const getLogo = () => {
    axios
      .get("/api/logo")
      .then((data) => {
        if (data !== "") {
          setLogo(`${process.env.REACT_APP_BASE_URL}/storage/${data}`);
        }
      })
      .catch((error) => {
        const { response } = error;

        toast.error(error.message);
      });
  };
  const handleSignin = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/login", {
        email,
        password,
      })
      .then((data) => {
        setLoading(false);
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("authToken", data.access_token);
        localStorage.setItem("permissions", JSON.stringify(data.permissions));
        dispatch(setUser(data.user));
        dispatch(setToken(data.access_token));
        dispatch(setPermissions(data.permissions));
        navigate("/");
        toast.success("به سیستم خوش آمدید.");
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <div className="grid h-screen place-items-center bg-white text-black">
      <div style={{ position: "relative", top: "-80px" }}>
        {logo && (
          <img
            src={logo}
            alt="My Image"
            width={200}
            height={72}
            className=" mx-auto w-40 mb-10"
          />
        )}
        <div
          className=" rounded-md px-8 py-10  w-96"
          style={{ border: "1px solid #c2c2c2" }}
        >
          <h1 className="mb-5 flex flex-row justify-center gap-2 text-xl">
            <strong>ورود به سیستم</strong>
            {/* <FaArrowCircleDown className="mt-1" /> */}
          </h1>
          <form onSubmit={handleSignin}>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoFocus
              className="ring-1 ring-inset ring-gray-300 w-full mb-3 p-2 rounded-md"
              placeholder="ایمیل"
              required={true}
            />

            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="ring-1 ring-inset ring-gray-300 w-full p-2 rounded-md"
              placeholder="گذرواژه"
              required={true}
            />
            {/* <Link
              to="/forget-password"
              className=" text-blue-500 hover:text-blue-700 text-xs font-semibold mb-3"
            >
              آیا گذرواژه را فراموش کرده اید؟
            </Link> */}

            <button
              type="submit"
              className="flex bg-blue-500 text-white hover:bg-blue-900 w-full justify-center p-2 rounded-sm my-5 "
              disabled={loading ? true : false}
            >
              {loading ? (
                <AiOutlineLoading3Quarters
                  className="m-1 animate-spin"
                  size={20}
                />
              ) : (
                <GoSignIn className="m-1 ml-2" size={20} />
              )}
              ورود
            </button>
          </form>

          {/* <p className="text-center text-gray-600 font-bold">
            Not a Member yet?{" "}
            <a
              href="/auth/signup"
              className="text-blue-500 hover:text-blue-700"
            >
              Sign Up
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
