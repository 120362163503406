import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import messageReducer from "./features/message";
import deleteReducer from "./features/deleteSlice";
import purchaseReducer from "./features/purchaseSlice";
import imgReducer from "./features/imgSlice";
import materialReducer from "./features/materialSlice";
import preparationReducer from "./features/preparationSlice";
import ProductReducer from "./features/ProductSlice";
import saleReducer from "./features/saleSlice";
import depositReducer from "./features/depositSlice";
import transactionReducer from "./features/transactionSlice";
import distributeReducer from "./features/distributeSlice";
import expenseReducer from "./features/expenseSlice";
import quoteReducer from "./features/quoteSlice";
import reportReducer from "./features/reportSlice";
import accountingReducer from "./features/accountingSlice";
import loanReducer from "./features/loanSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    message: messageReducer,
    delete: deleteReducer,
    purchase: purchaseReducer,
    img: imgReducer,
    material: materialReducer,
    preparation: preparationReducer,
    distribute: distributeReducer,
    product: ProductReducer,
    sale: saleReducer,
    deposit: depositReducer,
    transaction: transactionReducer,
    expense: expenseReducer,
    accounting: accountingReducer,
    quote: quoteReducer,
    report: reportReducer,
    loan: loanReducer,
  },
});
