import React, { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  showCreateForm,
  showReportForm,
  showReportPaymentForm,
} from "../../redux/features/saleSlice";

const NavSale = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative z-10" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex hover:text-white font-bold p-1"
      >
        فروشات
        <RiArrowDropDownLine size={24} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-md">
          <Link
            to="/customers"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            مشتریان
          </Link>
          <hr />
          <button
            onClick={() => dispatch(showCreateForm())}
            className="block w-full text-start px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            ثبت فروشات
          </button>
          <Link
            to="/sales"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            لیست فروشات
          </Link>
          <Link
            to="/customer/deposits"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            سپرده گذاری ها
          </Link>
          <Link
            to="/sales/payments"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            دریافتی
          </Link>
          <hr />
          <button
            onClick={() => dispatch(showReportForm())}
            className="block w-full text-start px-4 py-2 text-gray-800 hover:bg-gray-200"
          >
            تهیه گذارش فروشات
          </button>
          <button
            onClick={() => dispatch(showReportPaymentForm())}
            className="block w-full text-start px-4 py-2 text-gray-800 hover:bg-gray-200"
          >
            تهیه گذارش دریافتی
          </button>
        </div>
      )}
    </div>
  );
};

export default NavSale;
