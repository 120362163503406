import { digitsFaToEn } from "@persian-tools/persian-tools";

export const toEn = (e) => {
  let str = e.target.value;
  if (str.charAt(str.length - 1) === "٫") {
    str = str.slice(0, -1) + ".";
    console.log(str);
  }
  // console.log(e.target.value);
  const value = digitsFaToEn(str);
  return value;
};
