import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Options from "../../components/roles/Options";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../components/Delete";
import { useSelector } from "react-redux";

function RoleList() {
  const [roles, setRoles] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);

  useEffect(() => {
    if (!deleteItem.id) {
      getRoles();
    }
  }, [deleteItem]);

  const getRoles = () => {
    axios
      .get("/api/roles")
      .then(({ data }) => {
        setRoles(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>وظایف</h3>
        <Link
          to={`/roles/create`}
          className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
        >
          <IoIosAddCircle className="ml-1" size={22} />
          اضافه کردن
        </Link>
      </div>
      <div className="p-4">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-start">#</th>
              <th className="px-4 py-2 text-start">عنوان</th>
              <th className="px-4 py-2 text-start"> کاربران </th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                } border`}
              >
                <td className="px-4 py-2">{index + 1}</td>
                <td className="px-4 py-2">{row.name}</td>
                <td className="flex px-4 py-2 gap-2">
                  {row.users &&
                    row.users.map((row, index) => (
                      <Link
                        className="text-blue-500 border border-blue-300 rounded-2xl px-2 hover:bg-blue-300 hover:text-white"
                        to={`/users/${row.id}`}
                      >
                        {row.name}
                      </Link>
                    ))}
                </td>
                <td className="px-4 py-2">
                  <Options role={row} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {deleteItem.id && <Delete />}
      </div>
    </div>
  );
}

export default RoleList;
