import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getEndOfMonthDate, getStartOfMonthDate } from "../lib/getStartEndDate";
import axios from "../lib/axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DueBills from "../components/dashboard/DueBills";
import DuePurchases from "../components/dashboard/DuePurchases";
import LastExpenses from "../components/dashboard/LastExpenses";
import DonutsChart1 from "../components/dashboard/DonutsChart1";
import DonutsChart2 from "../components/dashboard/DonutsChart2";
import DonutsChart4 from "../components/dashboard/DonutsChart4";
import DonutsChart3 from "../components/dashboard/DonutsChart3";

const localizer = momentLocalizer(moment);

const events = [
  {
    id: 1,
    title: "Company Retreat",
    start: new Date(2024, 6, 4, 9, 0, 0), // July is represented by index 6
    end: new Date(2024, 6, 6, 17, 0, 0),
    url: "/accounts",
  },
  {
    id: 2,
    title: "Team Building Workshop",
    start: new Date(2024, 6, 10, 10, 0, 0),
    end: new Date(2024, 6, 10, 15, 0, 0),
  },
  {
    id: 3,
    title: "Client Presentation",
    start: new Date(2024, 6, 18, 13, 0, 0),
    end: new Date(2024, 6, 18, 15, 0, 0),
  },
];

const eventStyleGetter = (event, start, end, isSelected) => {
  let backgroundColor = "#f0f0f0"; // Default background color
  console.log("dfdsfdsf", event);

  if (event.start < new Date() && event.balance_amount == 0) {
    backgroundColor = "#3CB371";
  } else if (event.start < new Date()) {
    backgroundColor = "#F08080";
  } else {
    backgroundColor = "#87CEFA";
  }

  const style = {
    backgroundColor,
  };

  return {
    style,
  };
};

const customMessages = {
  today: "امروز",
  previous: "قبلی",
  next: "بعدی",
  month: "ماه",
  week: "هفته",
  day: "روز",
};

const views = {
  month: true,
};

function Dashboard() {
  const [bills, setBills] = useState([]);
  const [billsGrowth, setBillsGrowth] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getBills();
    getBillGrowth();
  }, []);

  const handleNavigate = (newDate, view) => {
    getBills(newDate);
  };

  const getBillGrowth = () => {
    axios
      .get(`/api/dashboard/bill-growth`)
      .then((data) => {
        const d = data.map((row) => ({ ...row, value: parseFloat(row.value) }));
        setBillsGrowth(d);
        // const d = data.map((row) => ({
        //   ...row,
        //   start: new Date(row.start),
        //   end: new Date(row.start),
        // }));
        // setBills(d);

        console.log(data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const getBills = (date = new Date()) => {
    const start = getStartOfMonthDate(date);
    const end = getEndOfMonthDate(date);

    axios
      .post(`/api/dashboard/bills`, { start, end })
      .then((data) => {
        const d = data.map((row) => ({
          ...row,
          start: new Date(row.start),
          end: new Date(row.start),
        }));
        setBills(d);

        console.log(data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const CustomEvent = ({ event }) => {
    return (
      <div style={{ whiteSpace: "normal" }}>
        <strong>{event.title}</strong>
        <br />
        <span>{event.description}</span>
      </div>
    );
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>داشبورد</h3>
      </div>

      <div className="flex flex-wrap my-6">
        <div className="lg:w-1/4 md:w-1/2 sm:w-1/2 p-4">
          <DonutsChart1 />
        </div>
        <div className="lg:w-1/4 md:w-1/2 sm:w-1/2 p-4">
          <DonutsChart2 />
        </div>
        <div className="lg:w-1/4 md:w-1/2 sm:w-1/2 p-4">
          <DonutsChart3 />
        </div>
        <div className="lg:w-1/4 md:w-1/2 sm:w-1/2 p-4">
          <DonutsChart4 />
        </div>
      </div>
      <div className=" flex flex-col lg:flex-row gap-4 p-4">
        <div className="lg:w-1/2">
          <div className=" border rounded-t-sm" dir="ltr">
            <p
              className="bg-gray-50 border-b rounded-t-sm px-2 py-1 mb-4"
              dir="rtl"
            >
              مشاهده روی جنتری
            </p>
            <div className="p-2">
              <Calendar
                onSelectEvent={(e) => navigate(`/sales/${e.id}/edit`)}
                localizer={localizer}
                events={bills}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
                onNavigate={handleNavigate}
                components={{
                  event: CustomEvent, // Assign custom event component
                }}
                messages={customMessages}
                views={views}
              />
            </div>
          </div>
          <div
            className=" border rounded-t-sm mt-8 "
            style={{ direction: "rtl" }}
          >
            <p
              className="bg-gray-50 border-b rounded-t-sm px-2 py-1 mb-4"
              dir="rtl"
            >
              گراف رشد
            </p>
            <div className="p-2">
              <ResponsiveContainer width="107%" height={500}>
                <AreaChart data={billsGrowth}>
                  <CartesianGrid />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="value"
                    fill="#AFEEEE"
                    stroke="#6cb2eb"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <DueBills />
          <DuePurchases />
          <LastExpenses />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
