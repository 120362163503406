import React, { useEffect, useState } from "react";
import axios from "../../lib/axios";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { toEn } from "../../lib/toEn";
import Select from "react-select";
import { showCreateForm } from "../../redux/features/saleSlice";
import { IoMdAddCircle } from "react-icons/io";

function Item({
  inputs,
  row,
  index,
  categories,
  items,
  setItems,
  removeItem,
  sale,
  products,
}) {
  const [product, setProduct] = useState({});
  const [tmpCodes, setTmpCodes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (products.length > 0) {
      const item = products.find((item) => item.id == row.product_inventory_id);
      setProduct(item);
      console.log("dddd", product);
      console.log("dddd", products);
    }
  }, [products, row.product_inventory_id]);
  useEffect(() => {
    if (product?.id) {
      setCodeList(product.category_id);
      setSizesList(product.code_id);
      setProdutsList(product.size_id);
    }
  }, [product]);

  const editItem = (index, i, value) => {
    const updatedItems = items.map((item, j) => {
      if (j === index) {
        let total = 0.0;
        if (i === "item_qty") {
          total = value * item.item_price;
        } else if (i === "item_price") {
          total = value * item.item_qty;
        } else {
          total = item.total_price;
        }
        console.log(item.qty);
        return { ...item, [i]: value, total_price: total };
      } else {
        return item;
      }
    });

    setItems(updatedItems);
  };

  const handleItemChange = (index, e, i) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    console.log(value);
    if (regex.test(value)) {
      editItem(index, i, value);
    }
  };

  const setCodeList = (id) => {
    const uniqueCodes = new Map();
    products.forEach((item) => {
      if (item.category_id == id) {
        uniqueCodes.set(item.code_id, {
          value: item.code_id,
          label: item.code,
        });
      }
    });

    // Convert Map values to an array
    const result = Array.from(uniqueCodes.values());
    setTmpCodes(result);
  };

  const setSizesList = (id) => {
    const uniqueSizes = new Map();
    products.forEach((item) => {
      if (item.code_id == id) {
        uniqueSizes.set(item.size_id, {
          value: item.size_id,
          label: item.size_name,
        });
      }
    });

    // Convert Map values to an array
    const result = Array.from(uniqueSizes.values());
    setSizes(result);
    console.log("codes", row);
    console.log("codes4", result);
  };

  const setProdutsList = (id, flag = true) => {
    const uniqueColors = new Map();

    products.forEach((item) => {
      const condition = flag
        ? item.size_id == id && item.code_id == product.code_id
        : item.size_id == id;
      if (condition) {
        uniqueColors.set(item.id, {
          value: item.id,
          label: item.color_name + "--" + item.total,
          color_id: item.color_id,
        });
      }
    });

    // Convert Map values to an array
    const result = Array.from(uniqueColors.values());
    setColors(result);
    console.log(row);
    console.log("collllors4", product);
  };

  return (
    <tr className="bg-gray-50">
      <td className=" px-1 py-2 border align-top ">
        {categories.length > 0 && (
          <Select
            className="ring-1 ring-inset ring-gray-300 "
            placeholder="انتخاب"
            form="submitForm"
            required
            onChange={(e) => {
              setCodeList(e.value);
              setProduct((prv) => ({
                ...prv,
                category_id: e.value,
                code_id: null,
                size_id: null,
                color_id: null,
              }));
            }}
            value={
              product?.category_id
                ? categories.find((i) => i.value === product.category_id)
                : null
            }
            options={categories}
          />
        )}
      </td>
      <td className=" px-1 py-2 border align-top ">
        <Select
          className="ring-1 ring-inset ring-gray-300  "
          placeholder="انتخاب"
          required
          onChange={(e) => {
            setSizesList(e.value);
            setProduct((prv) => ({
              ...prv,
              code_id: e.value,
              size_id: null,
              color_id: null,
            }));
          }}
          value={
            product?.code_id
              ? tmpCodes.find((i) => i.value === product.code_id)
              : null
          }
          options={tmpCodes}
        />
      </td>
      <td className=" px-1 py-2 border align-top ">
        <Select
          className="ring-1 ring-inset ring-gray-300 "
          placeholder="انتخاب"
          required
          onChange={(e) => {
            setProdutsList(e.value, true);
            setProduct((prv) => ({ ...prv, size_id: e.value, color_id: null }));
          }}
          value={
            product?.size_id
              ? sizes.find((i) => i.value === product.size_id)
              : null
          }
          options={sizes}
        />
      </td>
      <td className=" px-1 py-2 border align-top ">
        <Select
          className="ring-1 ring-inset ring-gray-300 w-full"
          placeholder="انتخاب"
          required
          onChange={(e) => {
            editItem(index, "product_inventory_id", e.value);
            setProduct((prv) => ({ ...prv, color_id: e.value }));
          }}
          value={
            product?.color_id
              ? colors.find((i) => i.color_id === product.color_id)
              : null
          }
          options={colors}
        />
      </td>

      <td className=" px-4 py-2 border align-top">
        <input
          name="item_qty"
          type="text"
          form="submitForm"
          step={0.01}
          min={0.0}
          pattern="^0*(\d+(\.\d{0,3})?|\.\d{1,3})$"
          value={row.item_qty}
          required
          onChange={(e) => handleItemChange(index, e, "item_qty")}
          className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
        />
      </td>
      <td className=" px-4 py-2 border align-top">
        <input
          name="item_price"
          type="text"
          form="submitForm"
          step={0.001}
          min={0.0}
          pattern="^0*(\d+(\.\d{0,3})?|\.\d{1,3})$"
          required
          value={row.item_price}
          onChange={(e) => handleItemChange(index, e, "item_price")}
          className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
        />
      </td>
      <td className=" px-4 py-2 border align-top">
        {row.total_price} {sale.currency}
      </td>
      <td className="px-2 py-2 border align-top">
        {items.length > 1 && (
          <button onClick={() => removeItem(index)}>
            <IoClose size={28} className="text-blue-500" />
          </button>
        )}
      </td>
    </tr>
  );
}

export default Item;
