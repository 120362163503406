import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  paymentCreateForm: false,
  paymentCreatePurchaseId: null,
  paymentEditObj: null,
  url: "",
  report: false,
  paymentReport: false,
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },
    showReportForm: (state) => {
      state.report = !state.report;
    },
    showReportPaymentForm: (state) => {
      state.paymentReport = !state.paymentReport;
    },
    showPaymentCreateForm: (state) => {
      state.paymentCreateForm = !state.paymentCreateForm;
    },
    setPaymentPurchaseId: (state, { payload }) => {
      state.paymentCreatePurchaseId = payload;
      console.log("paaaayload", state.paymentCreatePurchaseId);
    },
    setPaymentObj: (state, { payload }) => {
      state.paymentEditObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  showCreateForm,
  showPaymentCreateForm,
  setPaymentObj,
  setPaymentPurchaseId,
  showReportForm,
  showReportPaymentForm,
} = purchaseSlice.actions;
export default purchaseSlice.reducer;
