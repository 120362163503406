import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import axios from "../../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../../components/Delete";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { setDelete } from "../../../redux/features/deleteSlice";
import toast from "react-hot-toast";
import { toEn } from "../../../lib/toEn";
import Select from "react-select";

function CodeList() {
  const [codes, setCodes] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const inputs = {
    code: "",
    product_category_id: "",
    description: "",
  };
  const [code, setCode] = useState(inputs);
  const [categories, setCategories] = useState([]);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!deleteItem.id) {
      getCodes();
      getCategories();
    }
  }, [deleteItem]);

  const getCategories = () => {
    axios
      .get("/api/settings/product/category/select2")
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCodes = () => {
    axios
      .get("/api/settings/codes")
      .then((data) => {
        setCodes(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCode = (index, value) => {
    setCode((prevValue) => ({ ...prevValue, [index]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setLoading(true);
    console.log("teaam", code);
    axios
      .post("/api/settings/code/create", { id, ...code })
      .then((data) => {
        console.log("dooooo", id);
        if (id) {
          setCodes((prevCode) =>
            prevCode.map((row) => {
              if (row.id === id) {
                return data;
              }
              return row;
            })
          );
        } else {
          setCodes([data, ...codes]);
        }
        setLoading(false);
        toast.success("موفقانه ثبت گردید.");
        setCode(inputs);
        setId(null);
      })
      .catch((error) => {
        setLoading(false);
        const message = error?.response?.data?.message;
        toast.error(message ? message : error.message);
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>کد محصولات</h3>
      </div>
      <div className="p-4 flex flex-row gap-6">
        <div className="w-2/3">
          <table className="table-auto w-full overflow-y-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 text-start">#</th>
                <th className="px-4 py-2 text-start">کد</th>
                <th className="px-4 py-2 text-start">کتگوری</th>
                <th className="px-4 py-2 text-start">توضیحات </th>
                <th className="px-4 py-2 text-start">عملیه ها</th>
              </tr>
            </thead>
            <tbody>
              {codes.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{row.id}</td>
                  <td className="px-4 py-2">{row.code}</td>
                  <td className="px-4 py-2">
                    {categories.length > 0 &&
                      categories.find(
                        (item) => item.value === row.product_category_id
                      ).label}
                  </td>
                  <td className="px-4 py-2">{row.description}</td>

                  <td className="px-4 py-2 flex gap-4">
                    <button
                      onClick={() => {
                        setCode({ ...row });
                        setId(row.id);
                      }}
                    >
                      <FaEdit className="text-green-500" />
                    </button>
                    <button
                      onClick={() =>
                        dispatch(
                          setDelete({
                            item: row,
                            url: `/api/settings/code/${row.id}`,
                          })
                        )
                      }
                    >
                      <MdDelete className="text-red-500" size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {deleteItem.id && <Delete />}
        </div>

        <form
          action="#"
          method="post"
          onSubmit={handleSubmit}
          className=" w-1/3  rounded-t-md "
        >
          <div className=" bg-gray-100 border px-4 py-2 rounded-t-md">
            فورم ثبت کد محصولات
          </div>

          <div className="p-8 border-x ">
            <div className="flex justify-between">
              <label htmlFor="name">
                {" "}
                کد<span className="text-red-500">*</span>
              </label>
              <input
                name="code"
                required
                onChange={(e) => updateCode("code", e.target.value)}
                value={code.code}
                placeholder="MAX-A01"
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>
            <div className="flex justify-between">
              <label htmlFor="name" className="w-1/3">
                کتگوری <span className="text-red-500">*</span>
              </label>
              {categories.length > 0 && (
                <Select
                  placeholder="انتخاب"
                  required
                  onChange={(e) =>
                    updateCode("product_category_id", e ? e.value : null)
                  }
                  classNamePrefix="select"
                  isClearable={true}
                  options={categories}
                  value={
                    code.product_category_id
                      ? categories.find(
                          (row) => row.value === code.product_category_id
                        )
                      : null
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: "0.2rem", // Adjust the padding value as needed
                    }),
                  }}
                  className="  mb-3 rounded-md w-2/3"
                />
              )}
            </div>
            <div className="flex justify-between">
              <label htmlFor="name"> توضیحات</label>
              <textarea
                placeholder="..."
                name="description"
                onChange={(e) => updateCode("description", e.target.value)}
                value={code.description}
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>
          </div>

          <div className="flex justify-end bg-gray-100 px-4 py-2 border">
            <button
              className="flex gap-2 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
              ) : (
                <IoMdAdd className="mt-1" />
              )}
              ثبت
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CodeList;
