import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../lib/axios";
import toast from "react-hot-toast";

export default function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState("");
  // const [message, setMessage] = useState({
  //   status: null,
  //   message: "",
  // });
  useEffect(() => {
    getLogo();
  }, []);

  const getLogo = () => {
    axios
      .get("/api/logo")
      .then((data) => {
        setLogo(`${process.env.REACT_APP_BASE_URL}/storage/${data}`);
      })
      .catch((error) => {
        const { response } = error;

        toast.error(error.message);
      });
  };
  const handleSignin = async (e) => {
    e.preventDefault();
    //   sendPasswordResetEmail(auth, email)
    //     .then((reslut) => {
    //       // Signed in

    //       setEmail("");
    //       setMessage({
    //         status: 201,
    //         message: "Successfully sent password reset link, to you email.",
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       const errorCode = error.code;
    //       const errorMessage = error.message;
    //       setMessage({ status: 400, message: errorCode });
    //     });
  };
  return (
    <div className="grid h-screen place-items-center bg-white text-black">
      <div style={{ position: "relative", top: "-80px" }}>
        {logo && (
          <img
            src={logo}
            alt="My Image"
            width={200}
            height={72}
            className=" mx-auto w-40 mb-10"
          />
        )}
        <div
          className=" rounded-md px-8 py-10  w-96"
          style={{ border: "1px solid #c2c2c2" }}
        >
          <h1 className="mb-5 flex flex-row justify-center gap-2 text-xl">
            <strong>تغییر گذرواژه</strong>
            {/* <FaArrowCircleDown className="mt-1" /> */}
          </h1>
          {/* {message.status && (
            <Message
              status={message.status}
              message={message.message}
              close={() => setMessage({ status: null, message: "" })}
            />
          )} */}
          <form onSubmit={handleSignin}>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="ring-1 ring-inset ring-gray-300 w-full mb-3 p-2 rounded-md"
              placeholder="ایمیل"
              required={true}
            />

            <button
              type="submit"
              className="bg-blue-500 text-white hover:bg-blue-900 w-full p-2 rounded-sm mb-5 "
            >
              دریافت لینک
            </button>
          </form>

          <p className="text-center text-gray-600 font-bold">
            <Link to="/login" className="text-blue-500 hover:text-blue-700">
              ورود
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
