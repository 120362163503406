import React, { useState, useRef, useEffect } from "react";
import { FaGear } from "react-icons/fa6";
import { Link } from "react-router-dom";
import axios from "../../lib/axios";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setReportForm } from "../../redux/features/reportSlice";

const Setting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const now = new Date();

    // Get year, month, date, hours, minutes, and seconds
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JS
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Format the date as "YYYY-MM-DD-HH-MM-SS"
    const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
    axios
      .get("api/get-backup", {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${formattedDate}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // toast.success("عملیات موفقانه انجام شد.");
        // dispatch(showReportForm());
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        console.log("reeees", error);
      });
  };

  return (
    <div className="relative z-10" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="hover:bg-gray-400 p-2 rounded-md"
      >
        <FaGear size={18} />
      </button>

      {isOpen && (
        <div className="absolute sm: right:0 lg:left-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-md">
          {/* <button
            onClick={() => dispatch(setReportForm())}
            className="w-full text-start flex  px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            گذارش عمومی
          </button>
          <hr /> */}
          <Link
            to="/roles"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            وظایف
          </Link>
          <Link
            to="/users"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            کاربران
          </Link>

          <Link
            to="/settings/colors"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            رنگ ها
          </Link>
          <Link
            to="/settings/sizes"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            سایز ها
          </Link>

          <Link
            to="/settings/material-categories"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            دسته بندی مواد خام
          </Link>
          <Link
            to="/settings/categories"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            دسته بندی محصولات
          </Link>
          <Link
            to="/settings/codes"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            کود محصولات
          </Link>
          <Link
            to="/settings/teams"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            تیم های خیاطی
          </Link>
          <Link
            to="/settings/employees"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            کارمندان
          </Link>
          <Link
            to="/settings/expense-categories"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            دسته بندی مصارف
          </Link>

          <hr />

          <button
            onClick={handleSubmit}
            className="w-full text-start flex  px-4 py-1 text-gray-800 hover:bg-gray-200"
            disabled={loading}
          >
            {loading && (
              <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
            )}
            {loading ? "در حال تهیه" : "تهیه بکاب"}
          </button>
          <Link
            to="/settings/setting"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            تنضیمات سیستم
          </Link>
        </div>
      )}
    </div>
  );
};

export default Setting;
