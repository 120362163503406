import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import axios from "../../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../../components/Delete";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { setDelete } from "../../../redux/features/deleteSlice";
import toast from "react-hot-toast";

function ColorList() {
  const [colors, setColors] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const [name, setName] = useState("");
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!deleteItem.id) {
      getColors();
    }
  }, [deleteItem]);

  const getColors = () => {
    axios
      .get("/api/settings/colors")
      .then((data) => {
        setColors(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/settings/color/create", { id, name })
      .then((data) => {
        console.log("dooooo", id);
        if (id) {
          setColors((prevColors) =>
            prevColors.map((row) => {
              if (row.id === id) {
                return data;
              }
              return row;
            })
          );
        } else {
          setColors([data, ...colors]);
        }
        setLoading(false);
        toast.success("موفقانه ثبت گردید.");
        setName("");
        setId(null);
      })
      .catch((error) => {
        setLoading(false);
        const message = error?.response?.data?.message;
        toast.error(message ? message : error.message);
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>رنگها</h3>
      </div>
      <div className="p-4 flex flex-row gap-6">
        <div className="w-2/3">
          <table className="table-auto w-full overflow-y-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 text-start">#</th>
                <th className="px-4 py-2 text-start">نام</th>
                <th className="px-4 py-2 text-start">عملیه ها</th>
              </tr>
            </thead>
            <tbody>
              {colors.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{row.id}</td>
                  <td className="px-4 py-2">{row.name}</td>
                  <td className="px-4 py-2 flex gap-4">
                    <button
                      onClick={() => {
                        setName(row.name);
                        setId(row.id);
                      }}
                    >
                      <FaEdit className="text-green-500" />
                    </button>
                    <button
                      onClick={() =>
                        dispatch(
                          setDelete({
                            item: row,
                            url: `/api/settings/color/${row.id}`,
                          })
                        )
                      }
                    >
                      <MdDelete className="text-red-500" size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {deleteItem.id && <Delete />}
        </div>

        <form
          action="#"
          method="post"
          onSubmit={handleSubmit}
          className=" w-1/3  rounded-t-md "
        >
          <div className=" bg-gray-100 border px-4 py-2 rounded-t-md">
            {" "}
            نام<span className="text-red-500">*</span>
          </div>

          <div className="p-8 border-x ">
            <input
              name="name"
              required
              onChange={(e) => setName(e.target.value)}
              value={name}
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-3/4"
            />
          </div>

          <div className="flex justify-end bg-gray-100 px-4 py-2 border">
            <button
              className="flex gap-2 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
              ) : (
                <IoMdAdd className="mt-1" />
              )}
              ثبت
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ColorList;
