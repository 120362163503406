import React, { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showCreateForm } from "../../redux/features/loanSlice";

const NavLoan = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative z-10" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex hover:text-white font-bold p-1"
      >
        قروض
        <RiArrowDropDownLine size={24} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-md">
          <Link
            to="/lenders"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            قرضه دهنده گان
          </Link>
          <hr />
          <Link
            to="/loans"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            لیست قروض
          </Link>
          <hr />
          {/* <button
            onClick={() => dispatch(showReportForm())}
            className="block w-full text-start px-4 py-2 text-gray-800 hover:bg-gray-200"
          >
            تهیه گذارش فروشات
          </button> */}
        </div>
      )}
    </div>
  );
};

export default NavLoan;
