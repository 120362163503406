import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Options from "../../components/users/Options";
import { useSelector } from "react-redux";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../components/Delete";

function UserList() {
  const [users, setUsers] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);

  useEffect(() => {
    if (!deleteItem.id) {
      getUsers();
    }
  }, [deleteItem]);

  const getUsers = () => {
    axios
      .get("/api/users")
      .then(({ data }) => {
        setUsers(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>کاربران</h3>
        <Link
          to={"/users/create"}
          className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
        >
          <IoIosAddCircle className="ml-1" size={22} />
          اضافه کردن
        </Link>
      </div>
      <div className="p-4">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-start">#</th>
              <th className="px-4 py-2 text-start">نام</th>
              <th className="px-4 py-2 text-start">ایمیل</th>
              <th className="px-4 py-2 text-start">وظیفه</th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{index + 1}</td>
                  <td className="px-4 py-2">{row.name}</td>
                  <td className="px-4 py-2">{row.email}</td>
                  <td className="px-4 py-2">
                    {row.role ? (
                      <Link
                        className="text-blue-500 border border-blue-300 rounded-2xl px-2 hover:bg-blue-300 hover:text-white"
                        to={`/roles/${row.role.id}`}
                      >
                        {row.role.name}
                      </Link>
                    ) : (
                      "ندارد"
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {" "}
                    <Options user={row} />{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {deleteItem.id && <Delete />}
      </div>
    </div>
  );
}

export default UserList;
