import React from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../redux/features/message";

export default function Message() {
  const status = useSelector((state) => state.message.status);
  const message = useSelector((state) => state.message.message);

  const dispatch = useDispatch();

  return (
    <p
      className={`${
        status === 201 ? "bg-green-500" : "bg-red-500"
      } text-white rounded-sm p-2 flex flex-row justify-between my-3`}
    >
      {message}
      <button
        onClick={() => dispatch(setMessage({ status: null, message: "" }))}
      >
        <FaTimes className="hover:text-lg" />
      </button>
    </p>
  );
}
