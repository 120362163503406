import React, { useEffect, useState } from "react";
import Options from "./Options";
import Delete from "../../Delete";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../lib/axios";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { showCreateForm } from "../../../redux/features/transactionSlice";
import Create from "./Create";
import Edit from "./Edit";

function TransactionList() {
  const [transactions, setTransactions] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const createForm = useSelector((state) => state.transaction.createForm);
  const editObj = useSelector((state) => state.transaction.editObj);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [records, setRecords] = useState(10);
  const [url, setUrl] = useState(`/api/transactions`);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    if (!deleteItem.from_account && !createForm && !editObj) {
      getTransactions();
    }
  }, [deleteItem, records, url, fromDate, toDate, createForm, editObj]);

  useEffect(() => {
    let delaySearch;
    const handleSearchDelay = () => {
      delaySearch = setTimeout(() => {
        console.log(`Performing search for: ${search}`);
        getTransactions();
      }, 800);
    };
    const handleKeyUp = () => {
      if (delaySearch) {
        clearTimeout(delaySearch);
      }
      handleSearchDelay();
    };
    handleKeyUp();
    return () => {
      clearTimeout(delaySearch);
    };
  }, [search]);

  const getTransactions = () => {
    axios
      .post(url, {
        search,
        records,
        fromDate,
        toDate,
      })
      .then((data) => {
        setTransactions(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="p-4">
      <hr />
      <div className="flex gap-8 mt-3">
        <select
          onChange={(e) => setRecords(e.target.value)}
          className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
        >
          <option className="p-0 m-0" value={10}>
            10
          </option>
          <option className="p-0 m-0" value={20}>
            20
          </option>
          <option className="p-0 m-0" value={50}>
            50
          </option>
          <option className="p-0 m-0" value={100}>
            100
          </option>
          <option className="p-0 m-0" value={500}>
            500
          </option>
        </select>
        <input
          type="text"
          className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
          value={search}
          placeholder="جستجو..."
          onChange={(e) => setSearch(e.target.value)}
        />
        <div>
          <label htmlFor="fromDate">از تاریخ</label>
          <input
            type="date"
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md mr-2"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="fromDate">تا تاریخ</label>
          <input
            type="date"
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md mr-2"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <div className="absolute left-4">
          <button
            onClick={() => dispatch(showCreateForm())}
            className="flex items-center rounded-md px-2 font-semibold  text-amber-400 bg-sky-700 hover:text-amber-700"
          >
            {" "}
            <FaMoneyBillTransfer className="ml-1  px-1  " size={40} />
            انتقال
          </button>
        </div>
      </div>
      <table className="table-auto w-full">
        <thead>
          <tr className="border-y">
            <th className="px-4 py-2 text-start">#</th>
            <th className="px-4 py-2 text-start">از حساب</th>
            <th className="px-4 py-2 text-start">مقدار برداشت</th>
            <th className="px-4 py-2 text-start">به حساب</th>
            <th className="px-4 py-2 text-start">مقدار تبدیلی</th>
            <th className="px-4 py-2 text-start">تاریخ</th>
            <th className="px-4 py-2 text-start">عملیه ها</th>
          </tr>
        </thead>
        <tbody>
          {transactions.data &&
            transactions?.data.map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                } border`}
              >
                <td className="px-4 py-2">{row.id}</td>
                <td className="px-4 py-2">{row.from_account}</td>
                <td className="px-4 py-2">{row.amount}</td>
                <td className="px-4 py-2">{row.to_account}</td>

                <td className="px-4 py-2">{row.change}</td>
                <td className="px-4 py-2">{row.date}</td>
                <td className="px-4 py-2">
                  <Options transaction={row} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="flex gap-4 justify-end p-4">
        {transactions.links &&
          transactions.links.length > 3 &&
          transactions.links.map((row, index) => (
            <button
              disabled={row.active || row.url === null}
              onClick={() => setUrl(row.url)}
              className={`border py-1 px-3 ${
                row.active ? "bg-blue-500 text-white" : "hover:bg-gray-300"
              }`}
            >
              {row.label}
            </button>
          ))}
        {deleteItem.id && <Delete />}
        {createForm && <Create />}
        {editObj && <Edit />}
      </div>
    </div>
  );
}

export default TransactionList;
