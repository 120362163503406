import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: null,
  message: "",
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, { payload }) => {
      state.status = payload.status;
      state.message = payload.message;
    },
  },
  extraReducers: (builder) => {},
});

export const { setMessage } = messageSlice.actions;
export default messageSlice.reducer;
