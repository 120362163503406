import React, { useState, useRef, useEffect } from "react";
import { FaGear } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { setDelete } from "../../redux/features/deleteSlice";
import { MdInventory } from "react-icons/md";
import { useDispatch } from "react-redux";

const Options = ({ product, edit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative " ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`hover:bg-gray-400 ${edit ? "p-1" : "p-2"} rounded-md z-0`}
      >
        <FaGear size={18} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-md z-50">
          {/* <Link
            to={`/product-materials/${product.id}/`}
            className="flex px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            <MdInventory className="ml-2 mt-1" />
            ثبت گدام
          </Link> */}

          {!edit && (
            <Link
              to={`/preparations/${product.id}/edit`}
              className="flex px-4 py-1 text-gray-800 hover:bg-gray-200"
            >
              <FaEdit className="ml-2 mt-1" />
              ویرایش
            </Link>
          )}
          <button
            onClick={() =>
              dispatch(
                setDelete({
                  item: product,
                  url: `/api/preparation/${product.id}/delete`,
                  navigateUrl: "/preparations",
                })
              )
            }
            className="flex px-4 py-1 text-gray-800 hover:bg-gray-200 w-full"
          >
            <MdDelete className="ml-2 mt-1" size={18} />
            حذف
          </button>
        </div>
      )}
    </div>
  );
};

export default Options;
