import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { showReportForm } from "../../redux/features/purchaseSlice";
import { FaCheck, FaDownload } from "react-icons/fa";

const ReportPurchase = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [suppliers, setSuppliers] = useState([]);
  const inputs = {
    from: "",
    to: "",
    supplier_id: "",
    stocked: "",
    category: "",
  };
  const [formData, setFormData] = useState(inputs);

  const navigate = useNavigate();

  useEffect(() => {
    getSuppliers();
  }, []);

  const getSuppliers = () => {
    axios
      .get("/api/suppliers/select2")
      .then((data) => {
        console.log(data);
        setSuppliers(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .post(
        "api/purchases/report",
        { ...formData },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `purchase-report.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // toast.success("عملیات موفقانه انجام شد.");
        // dispatch(showReportForm());
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        console.log("reeees", error);
      });
  };
  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>فورم گذارش دهی خریداری</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => dispatch(showReportForm())}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createPurchaseForm"
            >
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="from" className="w-1/3 md:w-1/4">
                  از تاریخ
                </label>
                <input
                  name="from"
                  type="date"
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      from: e.target.value,
                    }))
                  }
                  value={formData.from}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="to" className="w-1/3 md:w-1/4">
                  تا به تاریخ
                </label>
                <input
                  name="to"
                  type="date"
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      to: e.target.value,
                    }))
                  }
                  value={formData.to}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  ثبت انبار گردیده؟
                </label>
                <div className="flex gap-8 justify-start  w-2/3 md:w-2/4">
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="stocked"
                      value="true"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          stocked: e.target.value,
                        }))
                      }
                    />
                    بلی
                  </label>
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="stocked"
                      value="false"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          stocked: e.target.value,
                        }))
                      }
                    />
                    نخیر
                  </label>
                </div>
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  کتگوری
                </label>
                <div className="flex gap-8 justify-start  w-2/3 md:w-2/4">
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="category"
                      value="balance"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          category: e.target.value,
                        }))
                      }
                    />
                    تصویه شده
                  </label>
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="category"
                      value="creditor"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          category: e.target.value,
                        }))
                      }
                    />
                    طلبکار
                  </label>
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="category"
                      value="overdue"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          category: e.target.value,
                        }))
                      }
                    />
                    سررسید
                  </label>
                </div>
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تامین کننده
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      supplier_id: e ? e.value : null,
                    }))
                  }
                  isClearable={true}
                  options={suppliers}
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createPurchaseForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaDownload className="mt-1 mx-2" size={16} />
                )}
                تهیه گذارش
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPurchase;
