import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Options from "../../components/customers/Options";
import { useSelector } from "react-redux";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../components/Delete";

function JournalItems() {
  const [customers, setCustomers] = useState({});
  const deleteItem = useSelector((state) => state.delete.item);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState(10);
  const [url, setUrl] = useState(`/api/accounting/journal-items`);

  useEffect(() => {
    if (!deleteItem.id) {
      getCustomers();
    }
  }, [deleteItem, records, url]);
  useEffect(() => {
    let delaySearch;
    const handleSearchDelay = () => {
      delaySearch = setTimeout(() => {
        console.log(`Performing search for: ${search}`);
        getCustomers();
      }, 800);
    };
    const handleKeyUp = () => {
      if (delaySearch) {
        clearTimeout(delaySearch);
      }
      handleSearchDelay();
    };
    handleKeyUp();
    return () => {
      clearTimeout(delaySearch);
    };
  }, [search]);

  const getCustomers = () => {
    axios
      .post(url, {
        search,
        records,
      })
      .then((data) => {
        setCustomers(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row bg-slate-50 border justify-between py-2 px-4">
        <h3>روزنامچه</h3>
      </div>
      <div className="p-4">
        <div className="flex gap-4 mt-8">
          <select
            onChange={(e) => setRecords(e.target.value)}
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
          >
            <option className="p-0 m-0" value={10}>
              10
            </option>
            <option className="p-0 m-0" value={20}>
              20
            </option>
            <option className="p-0 m-0" value={50}>
              50
            </option>
            <option className="p-0 m-0" value={100}>
              100
            </option>
            <option className="p-0 m-0" value={500}>
              500
            </option>
          </select>
          <input
            type="text"
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
            value={search}
            placeholder="جستجو..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr className="border-y">
              <th className="px-4 py-2 text-start">#</th>
              <th className="px-4 py-2 text-start">تاریخ</th>
              <th className="px-4 py-2 text-start">حساب</th>
              <th className="px-4 py-2 text-start">پارتنر</th>
              <th className="px-4 py-2 text-start">مقدار به اف</th>
              <th className="px-4 py-2 text-start">دیبت</th>
              <th className="px-4 py-2 text-start">کریدت</th>
              <th className="px-4 py-2 text-start">جورنال</th>
            </tr>
          </thead>
          <tbody>
            {customers.data &&
              customers?.data.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{row.id}</td>
                  <td className="px-4 py-2">{row.date}</td>
                  <td className="px-4 py-2">{row.name}</td>
                  <td className="px-4 py-2">{row.partner}</td>
                  <td className="px-4 py-2">{row.account_in_currency}</td>
                  <td className="px-4 py-2">{row.debit}</td>
                  <td className="px-4 py-2">{row.credit}</td>
                  <td className="px-4 py-2">{row.journal}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex gap-4 justify-end p-4">
          {customers.links &&
            customers.links.length > 3 &&
            customers.links.map((row, index) => (
              <button
                disabled={row.active || row.url === null}
                onClick={() => setUrl(row.url)}
                className={`border py-1 px-3 ${
                  row.active ? "bg-blue-500 text-white" : "hover:bg-gray-300"
                }`}
              >
                {row.label}
              </button>
            ))}
          {deleteItem.id && <Delete />}
        </div>
      </div>
    </div>
  );
}

export default JournalItems;
