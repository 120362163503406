import React, { useState, useRef, useEffect } from "react";
import { FaDownload, FaGear, FaMoneyBill1Wave } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { setDelete } from "../../redux/features/deleteSlice";
import { useDispatch } from "react-redux";
import {
  setPaymentSaleId,
  showPaymentCreateForm,
} from "../../redux/features/saleSlice";
import axios from "../../lib/axios";

const Options = ({ sale, edit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDownload = (row) => {
    axios
      .get(`/api/sale/${row.id}/download-pdf`, {
        responseType: "blob",
      })
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `bill-#${row.id}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  return (
    <div className="relative " ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="hover:bg-gray-400 p-2 rounded-md z-0"
      >
        <FaGear size={18} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-md z-50">
          <button
            onClick={() => {
              dispatch(showPaymentCreateForm());
              dispatch(setPaymentSaleId(sale.id));
            }}
            className="flex px-4 py-1 text-gray-800 hover:bg-gray-200 w-full"
          >
            <FaMoneyBill1Wave className="ml-2 mt-1" size={16} />
            ثبت پرداخت
          </button>
          <button
            onClick={() => {
              handleDownload(sale);
            }}
            className="flex px-4 py-1 text-gray-800 hover:bg-gray-200 w-full"
          >
            <FaDownload className="ml-2 mt-1" size={16} />
            دانلود pdf
          </button>
          {!edit && (
            <Link
              to={`/sales/${sale.id}/edit`}
              className="flex px-4 py-1 text-gray-800 hover:bg-gray-200"
            >
              <FaEdit className="ml-2 mt-1" />
              ویرایش
            </Link>
          )}
          <button
            onClick={() =>
              dispatch(
                setDelete({
                  item: sale,
                  url: `/api/sale/${sale.id}/delete`,
                  navigateUrl: edit ? `/sales` : "",
                })
              )
            }
            className="flex px-4 py-1 text-gray-800 hover:bg-gray-200 w-full"
          >
            <MdDelete className="ml-2 mt-1" size={18} />
            حذف
          </button>
        </div>
      )}
    </div>
  );
};

export default Options;
