import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  editObj: null,
};

export const preparationSlice = createSlice({
  name: "preparation",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      console.log("wwwwwwworking");
      state.createForm = !state.createForm;
    },

    setPreparationObj: (state, { payload }) => {
      state.editObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { showCreateForm, setPreparationObj } = preparationSlice.actions;
export default preparationSlice.reducer;
