import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  editObj: null,
};

export const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },

    setEditObj: (state, { payload }) => {
      console.log("norkkking", payload);
      state.editObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { showCreateForm, setEditObj } = depositSlice.actions;
export default depositSlice.reducer;
