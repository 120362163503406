import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: "",
};

export const imgSlice = createSlice({
  name: "img",
  initialState,
  reducers: {
    setImg: (state, { payload }) => {
      state.url = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setImg } = imgSlice.actions;
export default imgSlice.reducer;
