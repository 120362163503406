import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import axios from "../../lib/axios";
import { Link, useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import Message from "../../components/Message";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../redux/features/message";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast from "react-hot-toast";
import { setPermissions as setPPermision } from "../../redux/features/authSlice";

function RoleEdit() {
  const { id } = useParams();
  const [role, setRole] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const status = useSelector((state) => state.message.status);
  const dispatch = useDispatch();

  useEffect(() => getRole(), [id]);

  const getRole = () => {
    axios
      .get(`/api/role/${id}`)
      .then(({ data }) => {
        setPermissions(data.permissions);

        console.log(permissions);
        setRole({
          id: data.id,
          name: data.name,
        });
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const updatePermission = (index, newValue) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = [...prevPermissions]; // Create a copy of the previous state array
      updatedPermissions[index] = {
        ...updatedPermissions[index],
        checked: newValue,
      }; // Update the specific object

      return updatedPermissions;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/api/role/update`, {
        role,
        permissions,
      })
      .then((data) => {
        setLoading(false);
        toast.success("ویرایش موفقانه به پایان رسید.");
        localStorage.setItem("permissions", JSON.stringify(data.permissions));
        dispatch(setPPermision(data.permissions));
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
    console.log(permissions);
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>ویرایش وظیفه</h3>
        <Link
          to="/roles"
          className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
        >
          بازگشت
          <IoMdArrowBack className="ml-1" size={22} />
        </Link>
      </div>
      <div className="p-4">
        <form action="#" method="post" onSubmit={handleSubmit}>
          {status && <Message />}
          <div className="flex gap-8">
            <label htmlFor="name">
              {" "}
              عنوان وظیفه <span className="text-red-500">*</span>
            </label>
            <input
              name="name"
              onChange={(e) =>
                setRole((prevRole) => ({ ...prevRole, name: e.target.value }))
              }
              value={role.name}
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md"
            />
          </div>
          <hr />
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4 m-4 ">
            {permissions &&
              permissions.map((row, index) => (
                <div
                  key={index}
                  className="w-60 flex justify-between border p-3 rounded-md"
                >
                  <label htmlFor="checkbox3">{row.description}</label>
                  <input
                    onChange={(e) => updatePermission(index, !row.checked)}
                    type="checkbox"
                    id={row.id}
                    name={row.id}
                    value={row.checked}
                    defaultChecked={row.checked}
                  />
                </div>
              ))}
          </div>
          <button
            className="flex gap-2 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700"
            type="submit"
            disabled={loading ? true : false}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
            ) : (
              <FaEdit className="mt-1" />
            )}
            ثبت ویرایش
          </button>
        </form>
      </div>
    </div>
  );
}

export default RoleEdit;
