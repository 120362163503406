import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import axios from "../../lib/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Message from "../../components/Message";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../redux/features/message";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import toast from "react-hot-toast";

function RoleCreate() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [assignedPermissions, setAssignedPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const status = useSelector((state) => state.message.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => getRole(), [id]);

  const getRole = () => {
    axios
      .get(`/api/permissions`)
      .then((data) => {
        setPermissions(data);

        console.log(data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const updateAssignedPermission = (id) => {
    const list = [...assignedPermissions];
    let index = list.indexOf(id);
    if (index !== -1) {
      list.splice(index, 1);
    } else {
      list.push(id);
    }
    setAssignedPermissions(list);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/api/role/create`, {
        name,
        assignedPermissions,
      })
      .then((data) => {
        setLoading(false);
        if (data.status === 201) {
          toast.success("یک ریکارت موفقانه اضافه گردید.");
          // dispatch(
          //   setMessage({
          //     status: data.status,
          //     message: "یک ریکارت موفقانه اضافه گردید.",
          //   })
          // );
          navigate(`/roles/${data.role.id}`);
        } else {
          toast.error(data.message);
          // dispatch(setMessage({ status: data.status, message: data.message }));
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
        // dispatch(
        //   setMessage({
        //     status: 400,
        //     message: error.message,
        //   })
        // );
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>ویرایش وظیفه</h3>
        <Link
          to="/roles"
          className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
        >
          بازگشت
          <IoMdArrowBack className="ml-1" size={22} />
        </Link>
      </div>
      <div className="p-4">
        <form action="#" method="post" onSubmit={handleSubmit}>
          {status && <Message />}
          <div className="flex gap-8">
            <label htmlFor="name">
              {" "}
              عنوان وظیفه <span className="text-red-500">*</span>
            </label>
            <input
              name="name"
              required
              onChange={(e) => setName(e.target.value)}
              value={name}
              className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md"
            />
          </div>
          <hr />
          <div className="flex flex-row gap-4 m-4 ">
            {permissions &&
              permissions.map((row, index) => (
                <div
                  key={index}
                  className="w-60 flex justify-between border p-3 rounded-md"
                >
                  <label htmlFor="checkbox3">{row.name}</label>
                  <input
                    onChange={(e) => updateAssignedPermission(row.id)}
                    type="checkbox"
                    id={row.id}
                    name={row.id}
                  />
                </div>
              ))}
          </div>
          <button
            className="flex gap-2 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700"
            type="submit"
            disabled={loading ? true : false}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
            ) : (
              <IoMdAdd className="mt-1" />
            )}
            ثبت وظیفه
          </button>
        </form>
      </div>
    </div>
  );
}

export default RoleCreate;
