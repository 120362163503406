import { useState } from "react";
import { useDispatch } from "react-redux";

import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { showCreateForm } from "../../../redux/features/transactionSlice";
import { toEn } from "../../../lib/toEn";
import { FaCheck } from "react-icons/fa";

const Create = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const inputs = {
    from_account_id: null,
    to_account_id: null,
    amount: "",
    change: "",
    date: "",
  };
  const [formData, setFormData] = useState(inputs);

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    axios
      .get("/api/accounts/select2")
      .then((data) => {
        console.log(data);
        setAccounts(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .post("api/transaction/create", { ...formData })
      .then((data) => {
        console.log("data", data);
        setLoading(false);
        toast.success("عملیات موفقانه انجام شد.");
        dispatch(showCreateForm());
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const numToEn = (e, index) => {
    const value = toEn(e);

    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      console.log(value);
      setFormData((prevValue) => ({
        ...prevValue,
        [index]: value,
      }));
    }
  };
  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>انتقال پول بین حسابات</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => dispatch(showCreateForm())}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createPurchaseForm"
            >
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  از حساب<span className="text-red-500">*</span>
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  required
                  onChange={(e) => {
                    setAccounts((prev) => {
                      return prev.filter((row) => row.id !== e.value);
                    });
                    setFormData((prevValue) => ({
                      ...prevValue,
                      from_account_id: e.value,
                    }));
                  }}
                  options={accounts}
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  مقدار برداشت <span className="text-red-500">*</span>
                </label>
                <input
                  name="amount"
                  type="text"
                  required
                  onChange={(e) => numToEn(e, "amount")}
                  value={formData.amount}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  به حساب<span className="text-red-500">*</span>
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  required
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      to_account_id: e.value,
                    }))
                  }
                  options={accounts}
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  مقدار تبدیلی<span className="text-red-500">*</span>
                </label>
                <input
                  name="amount"
                  type="text"
                  required
                  onChange={(e) => numToEn(e, "change")}
                  value={formData.change}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تاریخ <span className="text-red-500">*</span>
                </label>
                <input
                  name="date"
                  type="date"
                  required
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      date: e.target.value,
                    }))
                  }
                  value={formData.date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createPurchaseForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaCheck className="mt-1 mx-2" />
                )}
                ثبت گردد
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create;
