import React, { useEffect, useState } from "react";
import axios from "../../lib/axios";
import { Link, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCheck, FaPlus } from "react-icons/fa6";
import toast from "react-hot-toast";

import Options from "../../components/sales/Options";
import Delete from "../../components/Delete";
import { useSelector } from "react-redux";
import Item from "../../components/sales/Item";

function SaleEdit() {
  const { id } = useParams();
  const inputs = {
    id: "",
    product_inventory_id: null,
    item_qty: 0,
    item_price: 0.0,
    total_price: 0,
    item_order: "",
  };
  const [customer, setCustomer] = useState({});
  const [categories, setCategories] = useState([]);
  const [codes, setCodes] = useState([]);
  const [products, setProducts] = useState([]);

  const [sale, setSale] = useState({});
  const [items, setItems] = useState([inputs]);
  const [deleted, setDeleted] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const paymentCreateForm = useSelector(
    (state) => state.sale.paymentCreateForm
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!paymentCreateForm) {
      getData();
      getProducts();
    }
    getCategories();
  }, [id, paymentCreateForm]);

  const getCategories = () => {
    axios.get(`/api/settings/product/category/select2`).then((data) => {
      setCategories(data);
    });
  };

  const getProducts = () => {
    axios
      .get(`/api/sales/products`)
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const getData = () => {
    axios
      .get(`/api/sale/${id}/edit`)
      .then((data) => {
        setCustomer(data.customer);
        if (data.items.length > 0) {
          setItems(data.items);
        } else {
          setItems([inputs]);
        }

        delete data.customer;
        delete data.items;
        setSale(data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const updateSale = (index, value) =>
    setSale((prevValue) => ({ ...prevValue, [index]: value }));

  const addItem = () => {
    setItems([...items, inputs]);
  };

  const removeItem = (index) => {
    if (items[index].id) {
      setDeleted([...deleted, items[index].id]);
    }
    const filters = items.filter((item, i) => i !== index);
    setItems(filters);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(items);
    setLoading(true);
    axios
      .put(`/api/sale/${id}/update`, { ...sale, items, deleted })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === 201) {
          const { sale } = data;
          setItems(sale.items);
          delete sale.customer;
          delete sale.items;
          setSale(sale);
          setDeleted([]);
          toast.success("ویرایش موفقانه انجام شد.");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4 ">
        <h3>نمبر فروش {id}</h3>
        <div className="flex gap-6">
          <Options sale={sale} edit={true} />
          <button onClick={addItem} className="flex hover:text-blue-400">
            <FaPlus className="mt-1 ml-1 font-bold" />
            اضافه کردن جنس
          </button>
          <button
            type="submit"
            form="submitForm"
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
            disabled={loading ? true : false}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
            ) : (
              <FaCheck className="mt-1 mx-2" />
            )}
            ذخیره
          </button>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex flex-col gap-4 text-gray-500">
          <Link
            className="text-sky-500 font-bold text-2xl hover:underline"
            to={`/customers/${1}/edit`}
          >
            {customer.name}
          </Link>
          <p>{customer.address1}</p>
          <p>
            نمبر تماس: <span dir="ltr">{customer.phone_number}</span>
          </p>
          <p>
            واتساب: <span dir="ltr">{customer.whatsapp_number}</span>
          </p>
          <p>
            ایمیل:{" "}
            <a
              href="mailto:azim@momtaz.af"
              target="_blank"
              className="text-blue-500 "
            >
              {customer.email}
            </a>
          </p>
        </div>
        <div className="text-gray-500">
          <table>
            <tbody>
              <tr>
                <th className="bg-gray-50 border w-36">سر رسید</th>
                <td className="bg-gray-50 border w-60 p-2">
                  <input
                    name="date"
                    type="date"
                    form="submitForm"
                    required
                    onChange={(e) => updateSale("due_date", e.target.value)}
                    value={sale.due_date}
                    className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
              </tr>
              <tr>
                <th className="bg-gray-50 border w-36">تاریخ </th>
                <td className="bg-gray-50 border w-60 p-2">
                  <input
                    name="date"
                    type="date"
                    form="submitForm"
                    required
                    onChange={(e) => updateSale("date", e.target.value)}
                    value={sale.date}
                    className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
              </tr>
              <tr>
                <th className=" border w-36">واحد پولی</th>
                <td className=" border w-60 p-2">
                  <select
                    name="currency"
                    required
                    form="submitForm"
                    onChange={(e) => updateSale("currency", e.target.value)}
                    value={sale.currency}
                    className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full bg-white"
                    disabled
                  >
                    <option value="دالر">دالر</option>
                    <option value="افغانی">افغانی</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th className=" border bg-gray-50 w-36"> مجموع </th>
                <td className=" border bg-gray-50 w-60 p-2">
                  {sale.total_amount} دالر
                </td>
              </tr>
              <tr>
                <th className=" border w-36">دریافتی</th>
                <td className=" border w-60 p-2">{sale.receipt_amount} دالر</td>
              </tr>
              <tr>
                <th className=" border bg-gray-50 w-36"> بیلانس </th>
                <td className=" border bg-gray-50 w-60 p-2">
                  {sale.balance_amount} دالر
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="p-4 text-gray-500">
        <form action="" id="submitForm" onSubmit={handleSubmit} method="post">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="px-4 py-2 text-start border">
                  نوع محصول <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  کود <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  سایز <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  رنگ <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  تعداد <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  قیمت فی عدد <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">قیمت مجموعی</th>
                <th className="px-4 py-2 text-start border"></th>
              </tr>
            </thead>
            <tbody>
              {items.map((row, index) => (
                <Item
                  key={index}
                  inputs={inputs}
                  row={row}
                  index={index}
                  categories={categories}
                  items={items}
                  setItems={setItems}
                  removeItem={removeItem}
                  sale={sale}
                  products={products}
                />
              ))}
            </tbody>
          </table>
        </form>
      </div>

      {deleteItem.id && <Delete />}
    </div>
  );
}

export default SaleEdit;

// [11, 12, 13, 14, 15, 16]

// clients_id[4, 6, 7, 9, 890]1, 2, 3, 4
