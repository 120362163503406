import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  createDistributeId: null,
  editObj: null,
  paymentObj: null,
  report: false,
};

export const distributeSlice = createSlice({
  name: "distribute",
  initialState,
  reducers: {
    showDistributeCreateForm: (state) => {
      state.createForm = !state.createForm;
    },
    showReportForm: (state) => {
      state.report = !state.report;
    },
    setDistributeId: (state, { payload }) => {
      state.createDistributeId = payload;
    },
    setDistributeObj: (state, { payload }) => {
      console.log("wooorking");
      state.editObj = payload;
    },
    setDistributePaymentObj: (state, { payload }) => {
      console.log("wooorking distribute payment");
      state.paymentObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  showDistributeCreateForm,
  setDistributeObj,
  setDistributeId,
  setDistributePaymentObj,
  showReportForm,
} = distributeSlice.actions;
export default distributeSlice.reducer;
