import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const loadUserInfo = () => {
  const authToken = localStorage.getItem("authToken");
  const user = localStorage.getItem("user");
  const permissions = localStorage.getItem("permissions");
  console.log("oernss", permissions);
  // Load any other information needed
  return { authToken, user, permissions };
};

const initialState = {
  user: {},
  authToken: "",
  permissions: [],
};

const userInfo = loadUserInfo();
initialState.authToken =
  userInfo.authToken === "null" ? null : userInfo.authToken;
initialState.user = JSON.parse(userInfo.user);
initialState.permissions = JSON.parse(userInfo.permissions);

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setToken: (state, { payload }) => {
      state.authToken = payload;
    },
    setPermissions: (state, { payload }) => {
      state.permissions = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setUser, setToken, setPermissions } = authSlice.actions;
export default authSlice.reducer;
