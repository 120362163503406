import React from "react";
import axios from "../../lib/axios";
import { IoMdCloudUpload } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setImg } from "../../redux/features/imgSlice";
import DocPreview from "./DocPreview";
import toast from "react-hot-toast";
import { FaDownload } from "react-icons/fa6";

const Document = ({ id, docs, setDocs }) => {
  const dispatch = useDispatch();
  const img = useSelector((state) => state.img.url);
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    if (file) {
      axios
        .post(`/api/purchase/docs/${id}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          setDocs([...docs, data.doc]);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const deleteFile = (docId) => {
    axios
      .delete(`/api/purchase/docs/${docId}/delete`)
      .then((data) => {
        setDocs((prevDocs) => {
          const filter = prevDocs.filter((doc, index) => doc.id !== docId);
          return filter;
        });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleDownload = (row) => {
    console.log("undefined", row);

    axios
      .get(`/api/purchase/docs/${row.id}/download`, {
        responseType: "blob",
      })
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", row.path); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  return (
    <div>
      <div className="flex items-center">
        <h2 className="mr-2">اسکن صورت حسابات</h2>
        <input
          type="file"
          id="file-upload"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        <label
          htmlFor="file-upload"
          className="relative cursor-pointer p-4 rounded-full"
        >
          <IoMdCloudUpload
            size={32}
            className="text-blue-500 hover:text-blue-800"
          />
        </label>
      </div>
      <hr />
      <div className=" flex relative gap-4 bg-gray-100 p-4">
        {docs.map((doc, index) => (
          <div className="p-2 w-52 shadow-lg bg-white border border-gray-200 ">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/storage/${doc.path}`}
              onClick={() =>
                dispatch(
                  setImg(
                    `${process.env.REACT_APP_BASE_URL}/storage/${doc.path}`
                  )
                )
              }
              className="bg-black cursor-pointer"
              alt=""
            />
            <div className="flex absolute bottom-6 gap-1">
              <button
                onClick={() => deleteFile(doc.id)}
                className=" bg-white p-2 border hover:bg-gray-200  rounded-full shadow-md font-bold"
              >
                <FaTrashAlt className="text-red-600 " size={18} />
              </button>

              <button
                onClick={(e) => handleDownload(doc)}
                className=" bg-white p-2 border hover:bg-gray-200  rounded-full shadow-md font-bold"
              >
                <FaDownload className="text-gray-600" size={18} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {img && <DocPreview />}
    </div>
  );
};

export default Document;
