import React, { useEffect, useState } from "react";
import { IoMdAdd, IoMdArrowBack, IoMdCloudUpload } from "react-icons/io";
import { FaCheck, FaDownload, FaTrashAlt } from "react-icons/fa";
import axios from "../../lib/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast from "react-hot-toast";
import { digitsFaToEn } from "@persian-tools/persian-tools";
import Select from "react-select";
import { toEn } from "../../lib/toEn";
import { useRef } from "react";
import { setImg } from "../../redux/features/imgSlice";
import { useDispatch, useSelector } from "react-redux";
import DocPreview from "../../components/purchases/DocPreview";
import Options from "../../components/expenses/Options";
import Delete from "../../components/Delete";
import Create from "../../components/expenses/payments/Create";
import List from "../../components/expenses/payments/List";
import Edit from "../../components/expenses/payments/Edit";

function ExpenseEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const img = useSelector((state) => state.img.url);
  const deleteItem = useSelector((state) => state.delete.item);
  const paymentCreateForm = useSelector(
    (state) => state.expense.paymentCreateForm
  );
  const paymentEditObj = useSelector((state) => state.expense.paymentEditObj);
  const [previewImage, setPreviewImage] = useState(null);
  const [expense, setExpense] = useState(null);
  const [categories, setCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [teams, setTeams] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [imgChanged, setImgChanged] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!paymentCreateForm && !paymentEditObj && !deleteItem.id) {
      getExpense();
    }
  }, [id, paymentCreateForm, paymentEditObj, deleteItem]);
  useEffect(() => {
    getCategories();
    getAccounts();
    getTeams();
    getEmployees();
  }, [id]);

  const getExpense = () => {
    axios
      .get(`/api/expense/${id}`)
      .then((data) => {
        console.log(data);

        if (data.file) {
          setPreviewImage(
            `${process.env.REACT_APP_BASE_URL}/storage/${data.file}`
          );
        }
        setExpense(data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const deleteFile = () => {
    axios
      .delete(`/api/expense/file/${id}/delete`)
      .then((data) => {
        if (data.status === 201) {
          updateExpense("file", "");
          setPreviewImage(null);
          toast.success("یک فایل موفقانه حذف گردید!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategories = () => {
    axios
      .get("/api/settings/expense/category/select2")
      .then((data) => {
        setCategories(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAccounts = () => {
    axios
      .get("/api/accounts/select2")
      .then((data) => {
        setAccounts(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTeams = () => {
    axios
      .get("/api/settings/team/select2")
      .then((data) => {
        setTeams(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getEmployees = () => {
    axios
      .get("/api/settings/employee/select2")
      .then((data) => {
        setEmployees(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateExpense = (index, value) =>
    setExpense((prevExpense) => ({ ...prevExpense, [index]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    Object.keys(expense).forEach((key) => {
      formData.append(key, expense[key]);
    });
    axios
      .post(`/api/expense/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === 201) {
          toast.success("یک ریکارت موفقانه ویرایش گردید.");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
      updateExpense("file", file);
      setImgChanged(true);
    }
  };

  const handleDownload = () => {
    axios
      .get(`/api/expense/file/${id}/download`, {
        responseType: "blob",
      })
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", expense.file); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  const numToEn = (index, e) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    console.log(value);
    if (regex.test(value)) {
      updateExpense(index, value);
    }
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>اضافه کردن مصارف</h3>
        <div className="flex gap-6">
          <Options expense={expense} edit={true} />
          <button
            form="submitExpenseCreate"
            type="submit"
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
            ) : (
              <FaCheck className="mt-1 mx-2" />
            )}
            ثبت
          </button>
        </div>
      </div>
      <div className="p-4">
        {expense?.id && (
          <form
            action=""
            id="submitExpenseCreate"
            onSubmit={handleSubmit}
            method="post"
            className="flex flex-col lg:flex-row gap-6"
          >
            <div className="w:full lg:w-4/6 p-6 border rounded-md  ">
              <div className=" flex flex-col lg:flex-row gap-8">
                <div className="w:full lg:w-2/3 flex flex-col gap-6">
                  <div className="flex gap-8 items-center">
                    <label htmlFor="name" className="w-1/3">
                      کتگوری <span className="text-red-500">*</span>
                    </label>
                    {categories.length > 0 && (
                      <Select
                        placeholder="انتخاب"
                        required
                        onChange={(e) =>
                          updateExpense(
                            "expense_category_id",
                            e ? e.value : null
                          )
                        }
                        classNamePrefix="select"
                        options={categories}
                        value={
                          expense.expense_category_id
                            ? categories.find(
                                (row) =>
                                  row.value === expense.expense_category_id
                              )
                            : null
                        }
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            padding: "0.2rem", // Adjust the padding value as needed
                          }),
                        }}
                        className="  mb-3 rounded-md w-2/3"
                      />
                    )}
                  </div>
                  <div className="flex gap-8 items-center">
                    <label htmlFor="title" className="w-1/3 ">
                      عنوان <span className="text-red-500">*</span>
                    </label>
                    <input
                      name="title"
                      required
                      onChange={(e) => updateExpense("title", e.target.value)}
                      value={expense.title}
                      placeholder="خرید اجناس..."
                      className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
                    />
                  </div>
                  <div className="flex gap-8 items-center">
                    <label htmlFor="title" className="w-1/3 ">
                      مقدار <span className="text-red-500">*</span>
                    </label>
                    <input
                      name="total"
                      required
                      onChange={(e) => numToEn("total", e)}
                      value={expense.total}
                      placeholder="325.00"
                      className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
                    />
                  </div>
                  <div className="flex gap-8 items-center">
                    <label htmlFor="title" className="w-1/3 ">
                      نرخ 1 دالر به افغانی{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      name="currency_rate"
                      required
                      onChange={(e) => numToEn("currency_rate", e)}
                      value={expense.currency_rate}
                      placeholder="71.20"
                      className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
                    />
                  </div>
                </div>
                <div className="w:full lg:w-1/3 lg:border-r lg:pr-4 ">
                  <div className="gap-8 flex-col">
                    <label htmlFor="description" className="w-full">
                      توضیحات:
                    </label>
                    <textarea
                      name="description"
                      onChange={(e) =>
                        updateExpense("description", e.target.value)
                      }
                      value={expense.description}
                      placeholder="بیشتر..."
                      rows={8}
                      className="ring-1 ring-inset ring-gray-300  mt-3 p-2 rounded-sm w-full  "
                    />
                  </div>
                </div>
              </div>
              <hr className="my-8" />
              <List payments={expense.payments} />
              <div>
                <div className="flex items-center">
                  <h2 className="mr-2">اسکن صورت حسابات</h2>
                  <input
                    type="file"
                    id="file-upload"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer p-4 rounded-full"
                  >
                    <IoMdCloudUpload
                      size={32}
                      className="text-blue-500 hover:text-blue-800"
                    />
                  </label>
                </div>
                <hr />
                {previewImage && (
                  <div className=" m-4 p-2 w-52 shadow-lg bg-white border border-gray-200 relative">
                    <img
                      src={previewImage}
                      onClick={() => dispatch(setImg(previewImage))}
                      className="bg-black cursor-pointer"
                      alt=""
                    />
                    <div className="flex absolute bottom-1 gap-1">
                      <button
                        type="button"
                        onClick={() => deleteFile()}
                        className=" bg-white p-2 border hover:bg-gray-200  rounded-full shadow-md font-bold"
                      >
                        <FaTrashAlt className="text-red-600 " size={18} />
                      </button>
                      {!imgChanged && (
                        <button
                          type="button"
                          onClick={(e) => handleDownload()}
                          className=" bg-white p-2 border hover:bg-gray-200  rounded-full shadow-md font-bold"
                        >
                          <FaDownload className="text-gray-600" size={18} />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w:full lg:w-2/6 ">
              <table className="w-full">
                <tr>
                  <th className="bg-gray-50 border text-start px-4 ">
                    تاریخ <span className="text-red-500">*</span>
                  </th>
                  <td className="bg-gray-50 border  p-2">
                    <input
                      name="date"
                      type="date"
                      required
                      onChange={(e) => updateExpense("date", e.target.value)}
                      value={expense.date}
                      className="ring-1 ring-inset ring-gray-300 p-2 rounded-md w-full"
                    />
                  </td>
                </tr>
                <tr>
                  <th className=" border text-start px-4">
                    پرداخت از <span className="text-red-500">*</span>
                  </th>
                  <td className=" border  p-2">
                    <Select
                      placeholder="انتخاب"
                      required
                      onChange={(e) => updateExpense("account_id", e.value)}
                      options={accounts.filter((row) =>
                        row.use_for.includes("مصارف")
                      )}
                      value={accounts.find(
                        (row) => row.value === expense.account_id
                      )}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          padding: "0.2rem", // Adjust the padding value as needed
                        }),
                      }}
                      className=" rounded-md w-full"
                    />
                  </td>
                </tr>
                {/* <tr>
                  <th className="bg-gray-50 border text-start px-4">
                    تیم\گروپ
                  </th>
                  <td className="bg-gray-50 border  p-2">
                    <Select
                      placeholder="انتخاب"
                      onChange={(e) =>
                        updateExpense("tailor_team_id", e ? e.value : null)
                      }
                      isClearable={true}
                      value={
                        expense.tailor_team_id
                          ? teams.find(
                              (row) => row.value === expense.tailor_team_id
                            )
                          : null
                      }
                      options={teams}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          padding: "0.2rem", // Adjust the padding value as needed
                        }),
                      }}
                      className=" rounded-md w-full"
                    />
                  </td>
                </tr> */}
                <tr>
                  <th className="border bg-gray-50 text-start px-4">کارمند</th>
                  <td className="border bg-gray-50  p-2">
                    <Select
                      placeholder="انتخاب"
                      onChange={(e) =>
                        updateExpense("employee_id", e ? e.value : null)
                      }
                      isClearable={true}
                      options={employees}
                      value={
                        expense.employee_id
                          ? employees.find(
                              (row) => row.value === expense.employee_id
                            )
                          : null
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          padding: "0.2rem", // Adjust the padding value as needed
                        }),
                      }}
                      className=" rounded-md w-full"
                    />
                  </td>
                </tr>
              </table>
              <table className="w-full mt-8">
                <tr>
                  <th className="bg-gray-50 border text-start px-4 ">مجموع</th>
                  <td className="bg-gray-50 border  p-2">{expense.total}</td>
                </tr>
                <tr>
                  <th className=" border text-start px-4">پرداخت شد</th>
                  <td className=" border  p-2">{expense.paid}</td>
                </tr>

                <tr>
                  <th className="border bg-gray-50 text-start px-4">بیلانس</th>
                  <td className="border bg-gray-50  p-2">{expense.balance}</td>
                </tr>
              </table>
            </div>
          </form>
        )}
      </div>
      {img && <DocPreview />}
      {deleteItem.id && <Delete />}
      {paymentCreateForm && <Create expense={expense} />}
      {paymentEditObj && <Edit />}
    </div>
  );
}

export default ExpenseEdit;
