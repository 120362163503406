import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../lib/axios";

function DuePurchase() {
  const [duePurchases, setDuePurchases] = useState([]);
  useEffect(() => {
    getDuePurchases();
  }, []);

  const getDuePurchases = () => {
    axios
      .get("/api/dashboard/due-purchases")
      .then((data) => {
        setDuePurchases(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className=" border rounded-t-sm mt-8">
      <p className="bg-gray-50 border-b rounded-t-sm px-2 py-1 mb-4" dir="rtl">
        سررسید خریداری ها
      </p>
      <div className="p-4">
        <table className="table-auto w-full border-t">
          <thead>
            <tr>
              <th className="px-4 py-2 text-start">حالت </th>
              <th className="px-4 py-2 text-start">خرید نمبر</th>
              <th className="px-4 py-2 text-start">سر رسید</th>
              <th className="px-4 py-2 text-start">تامین کننده</th>
              <th className="px-4 py-2 text-start">بیلانس</th>
            </tr>
          </thead>
          <tbody>
            {duePurchases &&
              duePurchases.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-1 py-2 text-white">
                    {row.balance > 0 ? (
                      <span className="bg-red-400 px-1 rounded-sm">باقی</span>
                    ) : (
                      <span className="bg-green-400 px-1 rounded-sm">
                        دریافت شد
                      </span>
                    )}
                  </td>
                  <td className="px-1 py-2 text-sky-500 hover:underline hover:text-sky-800">
                    <Link to={`/purchases/${row.id}/edit`}>{row.id}</Link>
                  </td>
                  <td className="px-1 py-2">{row.date}</td>
                  <td className="px-1 py-2 text-sky-500 hover:underline hover:text-sky-800">
                    <Link to={`/suppliers/${row.sid}/edit`}>{row.name}</Link>
                  </td>
                  <td className="px-1 py-2">{row.balance} دالر</td>

                  <td className="px-4 py-2"></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <p className="bg-gray-50 border-t rounded-t-sm px-2 py-1 text-center">
        {" "}
        <Link
          to="/purchases"
          className="text-sky-500 hover:underline hover:text-sky-800"
        >
          لیست همه
        </Link>
      </p>
    </div>
  );
}

export default DuePurchase;
