import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportForm: false,
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportForm: (state) => {
      state.reportForm = !state.reportForm;
    },
  },
  extraReducers: (builder) => {},
});

export const { setReportForm } = reportSlice.actions;
export default reportSlice.reducer;
