import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";

function NotFound() {
  return (
    <div
      className="flex justify-center items-center h-screen bg-gray-200"
      style={{ height: "calc(100vh - 45px)" }}
    >
      <div className="flex flex-col items-center">
        <RiErrorWarningLine className="text-red-500 text-6xl mb-4 " />
        <h1 className="text-4xl font-semibold mb-2">404 - صفحه یافت نشد</h1>
        <p className="text-lg">صفحه که شما جستجو کردید موجود نیست.</p>
      </div>
    </div>
  );
}

export default NotFound;
