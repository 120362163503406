import React, { useEffect, useState } from "react";
import { IoMdAdd, IoMdArrowBack } from "react-icons/io";
import axios from "../../lib/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCheck, FaPlus } from "react-icons/fa6";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { IoAddCircle } from "react-icons/io5";

import Document from "../../components/purchases/Document";
import Options from "../../components/purchases/Options";
import Delete from "../../components/Delete";
import Select from "react-select";
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { toEn } from "../../lib/toEn";

function Material() {
  const { id } = useParams();
  const inputs = {
    id: "",
    color_id: "",
    material_category_id: "",
    entry_qtys: 0.0,
    qty_to_unit: 1,
    unit: "",
    qty_price: 0.0,
    purchase_id: id,
    purchase_item_id: null,
  };
  const [supplier, setSupplier] = useState({});
  const [purchase, setPurchase] = useState({});
  const [items, setItems] = useState([]);
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    getData();
    getColors();
    getCategories();
  }, [id]);

  const getData = () => {
    axios
      .get(`/api/purchase/${id}/edit`)
      .then(async (data) => {
        setSupplier(data.supplier);
        if (data.items.length > 0) {
          const items = await appendMaterial(data.items);
          console.log(items);
          setItems(items);
        }
        delete data.supplier;
        delete data.items;
        delete data.docs;
        setPurchase(data);
        console.log("wwwwwwwwwwwwwww", data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };

  const getCategories = () => {
    axios.get(`/api/settings/material/category/select2`).then((data) => {
      setCategories(data);
    });
  };

  const appendMaterial = async (items) => {
    let editItems = items.map((item) => {
      if (item.material === null) {
        item.material = {
          ...inputs,
          entry_qtys: item.qtys,
          purchase_item_id: item.id,
          qty_price: item.qty_price,
        };
      }
      return item;
    });
    return editItems;
  };

  const updateMaterial = (index, column, value) => {
    const tmpItems = [...items];

    tmpItems[index].material = { ...tmpItems[index].material, [column]: value };
    setItems(tmpItems);
    console.log(items);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`/api/purchase-material`, { items })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === 201) {
          const { purchase } = data;
          setItems(purchase.items);
          toast.success("ثبت موفقانه انجام شد.");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        // toast.error(error.message);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleItemChange = (index, e, qty_price) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    console.log(value);
    if (regex.test(value)) {
      updateMaterial(index, "qty_to_unit", value);
      updateMaterial(index, "qty_price", qty_price / value);
    }
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4 ">
        <h3>
          {" "}
          ثبت اجناس در گدام{" "}
          <Link
            to={`/purchases/${purchase.id}/edit`}
            className="text-sky-500 underline"
          >
            {supplier.name} {"-"} خریداری # {purchase.id}
          </Link>
        </h3>
        <div className="flex gap-6">
          {/* <Options purchase={purchase} edit={true} />
          <button onClick={addItem} className="flex hover:text-blue-400">
            <FaPlus className="mt-1 ml-1 font-bold" />
            اضافه کردن جنس
          </button> */}
          {items.length > 0 && (
            <button
              type="submit"
              form="submitForm"
              className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
              ) : (
                <FaCheck className="mt-1 mx-2" />
              )}
              ذخیره
            </button>
          )}
        </div>
      </div>
      <form action="" id="submitForm" onSubmit={handleSubmit} method="post">
        <div className="p-4 text-gray-500">
          <table className="table-auto w-full">
            <tbody>
              {items.map((row, index) => (
                <>
                  <tr
                    key={index}
                    className="bg-amber-600 text-white border border-amber-600"
                  >
                    <td colSpan={6} className="  px-4 py-2 align-top gap-4 ">
                      <span className="ml-4"> نام جنس : {row.name} </span> |
                      <span className="ml-4"> تعداد : {row.qtys} </span> |
                      <span className="ml-4">
                        {" "}
                        قیمت فی عدد : {row.qty_price} {purchase.currency}{" "}
                      </span>{" "}
                      |
                      <span className="ml-4">
                        {" "}
                        قیمت مجموعی : {row.total_price} {purchase.currency}{" "}
                      </span>{" "}
                    </td>
                  </tr>
                  {row.material && (
                    <>
                      <tr className="bg-gray-50  border border-amber-600">
                        <th className="px-4 py-2 text-start border border-amber-600">
                          {" "}
                          کتگوری <span className="text-red-500">*</span>{" "}
                        </th>
                        <th className="px-4 py-2 text-start border border-amber-600">
                          {" "}
                          رنگ <span className="text-red-500">*</span>{" "}
                        </th>

                        <th className="px-4 py-2 text-start border border-amber-600">
                          تعداد <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-2 text-start border border-amber-600">
                          مقیاس<span className="text-red-500">*</span>
                        </th>
                        <th className="px-2 py-2 text-start border border-amber-600 ">
                          فی واحد\
                          <br /> به مقیاس<span className="text-red-500">*</span>
                        </th>
                        <th className="px-2 py-2 text-start border border-amber-600 ">
                          {" "}
                          قیمت فی دانه\
                          <br /> مقیاس
                          <span className="text-red-500">*</span>{" "}
                        </th>
                      </tr>
                      <tr className="bg-white border-b">
                        <td className=" px-2 py-1 border border-amber-600 align-top w-40">
                          <Select
                            className=" w-full"
                            placeholder="انتخاب"
                            required
                            onChange={(e) =>
                              updateMaterial(
                                index,
                                "material_category_id",
                                e.value
                              )
                            }
                            options={categories}
                            value={categories.find(
                              (value, index) =>
                                value.value ===
                                row.material.material_category_id
                            )}
                            form="submitForm"
                          />
                        </td>
                        <td className=" px-2 py-1 border border-amber-600 align-top w-40">
                          <Select
                            className=" w-full"
                            placeholder="انتخاب"
                            required
                            onChange={(e) =>
                              updateMaterial(index, "color_id", e.value)
                            }
                            options={colors}
                            value={colors.find(
                              (value, index) =>
                                value.value === row.material.color_id
                            )}
                            form="submitForm"
                          />
                        </td>
                        <td className="px-4 py-2 border border-amber-600 align-top">
                          <input
                            name="entry_qtys"
                            type="text"
                            required
                            form="submitForm"
                            disabled
                            value={row.material.entry_qtys}
                            onChange={(e) =>
                              updateMaterial(
                                index,
                                "entry_qtys",
                                e.target.value
                              )
                            }
                            className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                          />
                        </td>
                        <td className=" px-4 py-2 border border-amber-600 align-top">
                          <input
                            name="unit"
                            type="text"
                            required
                            form="submitForm"
                            value={row.material.unit}
                            placeholder="متر"
                            onChange={(e) =>
                              updateMaterial(index, "unit", e.target.value)
                            }
                            className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                          />
                        </td>
                        <td className=" px-4 py-2 border border-amber-600 align-top">
                          <input
                            name="qty_to_unit"
                            type="text"
                            required
                            form="submitForm"
                            value={row.material.qty_to_unit}
                            onChange={(e) =>
                              handleItemChange(index, e, row.qty_price)
                            }
                            className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                          />
                        </td>
                        <td className=" px-4 py-2 border border-amber-600 align-top flex flex-row gap-3">
                          <input
                            name="qty_price"
                            type="text"
                            required
                            form="submitForm"
                            disabled
                            value={row.material.qty_price}
                            onChange={(e) =>
                              updateMaterial(index, "qty_price", e.target.value)
                            }
                            className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                          />
                          {purchase.currency}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default Material;
