import Axios from "axios";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    // Perform any actions before sending the request, such as adding authentication headers
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    // Handle the response data
    return response.data;
  },
  (error) => {
    // Handle any errors that occur during the request
    return Promise.reject(error);
  }
);

export default axios;
