import React from "react";
import { FaMoneyBillWave } from "react-icons/fa";
import Options from "./Options";

function List({ payments }) {
  return (
    <div>
      <div className="flex items-center">
        <h2 className="mr-2">لیست پرداختی ها</h2>

        <FaMoneyBillWave
          size={32}
          className="text-blue-500 hover:text-blue-800 m-3"
        />
      </div>
      <div className="py-2">
        <table className="table-auto w-full">
          <thead>
            <tr className="border-y">
              <th className="px-4 py-2 text-start">#</th>
              <th className="px-4 py-2 text-start">تاریخ</th>
              <th className="px-4 py-2 text-start">نوت</th>
              <th className="px-4 py-2 text-start">مقدار</th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                } border`}
              >
                <td className="px-4 py-2">{row.id}</td>
                <td className="px-4 py-2">{row.date}</td>

                <td className="px-4 py-2">{row.note}</td>
                <td className="px-4 py-2">{row.amount}</td>
                <td className="px-4 py-2">
                  <Options payment={row} />{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default List;
