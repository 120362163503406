import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  const authToken = useSelector((state) => state.auth.authToken);

  return authToken ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
