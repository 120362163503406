import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  editObj: null,
  report: null,
};

export const materialSlice = createSlice({
  name: "material",
  initialState,

  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },
    showReportForm: (state) => {
      state.report = !state.report;
    },

    setMaterialObj: (state, { payload }) => {
      state.editObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { showCreateForm, setMaterialObj, showReportForm } =
  materialSlice.actions;
export default materialSlice.reducer;
