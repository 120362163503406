import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Sector,
  Tooltip,
  Label,
} from "recharts";
import axios from "../../lib/axios";

const COLORS = ["#00C49F", "#FFBB28", "#f8f9fa"];

function DonutsChart1() {
  const [weekDonuts, setWeekDonuts] = useState([]);
  useEffect(() => {
    getWeekDonuts();
  }, []);

  const getWeekDonuts = () => {
    axios
      .get("/api/dashboard/last-week")
      .then((data) => {
        const d = data.map((row) => ({ ...row, value: parseFloat(row.value) }));
        setWeekDonuts(d);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [activeIndex, setActiveIndex] = useState(null);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" aspect={1.5}>
      <PieChart>
        <Pie
          data={weekDonuts}
          cx="50%"
          cy="50%"
          innerRadius={80}
          outerRadius={105}
          paddingAngle={1}
          dataKey="value"
          activeIndex={activeIndex}
          activeShape={(props) => {
            const RADIAN = Math.PI / 180;
            const {
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              startAngle,
              endAngle,
              fill,
              payload,
              percent,
            } = props;

            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const sx = cx + (outerRadius + 10) * cos;
            const sy = cy + (outerRadius + 10) * sin;
            const mx = cx + (outerRadius + 30) * cos;
            const my = cy + (outerRadius + 30) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;
            const textAnchor = cos >= 0 ? "start" : "end";

            return (
              <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                  {payload.name}
                </text>
                <Sector
                  cx={cx}
                  cy={cy}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  fill={fill}
                />
                <Sector
                  cx={cx}
                  cy={cy}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  innerRadius={outerRadius}
                  outerRadius={outerRadius + 6}
                  fill={fill}
                />
                <path
                  d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                  stroke={fill}
                  fill="none"
                />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text
                  x={ex + (cos >= 0 ? 1 : -1) * 12}
                  y={ey}
                  dy={-10}
                  textAnchor={textAnchor}
                  fill="#333"
                >
                  {`${payload.value} (${(percent * 100).toFixed(2)}%)`}
                </text>
              </g>
            );
          }}
        >
          {weekDonuts.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label value="این هفته" position="center" />
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default DonutsChart1;
