import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { setDistributePaymentObj } from "../../redux/features/distributeSlice";
import { toEn } from "../../lib/toEn";
import { FaCheck, FaDownload, FaTrashAlt } from "react-icons/fa";
import { IoMdCloudUpload } from "react-icons/io";
import { setImg } from "../../redux/features/imgSlice";
import DocPreview from "../purchases/DocPreview";

const Payment = () => {
  const dispatch = useDispatch();
  const paymentObj = useSelector((state) => state.distribute.paymentObj);
  const img = useSelector((state) => state.img.url);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(paymentObj);
  const [accounts, setAccounts] = useState([]);
  const [previewImage, setPreviewImage] = useState(
    paymentObj.file
      ? `${process.env.REACT_APP_BASE_URL}/storage/${paymentObj.file}`
      : null
  );

  const balance =
    paymentObj.type === "دالر"
      ? paymentObj.total_payment - paymentObj.paid_tailor_fee_amount
      : paymentObj.total_payment -
        paymentObj.paid_tailor_fee_amount / paymentObj.currency_rate;
  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    axios
      .get("/api/accounts/select2")
      .then((data) => {
        setAccounts(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .put(`api/distribute/${paymentObj.id}/payment`, { ...formData })
      .then((data) => {
        console.log("data", data);
        setLoading(false);
        if (data.status === 201) {
          toast.success("عملیات موفقانه انجام شد.");
          close();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const close = () => {
    dispatch(setDistributePaymentObj(null));
  };

  const handleItemChange = (index, e) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      setFormData((prevValue) => ({
        ...prevValue,
        [index]: value,
      }));
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formD = new FormData();
    formD.append("file", file);

    if (file) {
      axios
        .post(`/api/distribute/file/${paymentObj.id}/upload`, formD, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          setPreviewImage(`${process.env.REACT_APP_BASE_URL}/storage/${data}`);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleDownload = () => {
    axios
      .get(`/api/distribute/file/${paymentObj.id}/download`, {
        responseType: "blob",
      })
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", paymentObj.file); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  const deleteFile = () => {
    axios
      .delete(`/api/distribute/file/${paymentObj.id}/delete`)
      .then((data) => {
        if (data.status === 201) {
          setPreviewImage(null);
          toast.success("یک فایل موفقانه حذف گردید!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>فورم ثبت پرداخت فیصدی خیاط</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => close()}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createSaleForm"
            >
              <div className="flex justify-between gap-8 items-center mb-3 font-bold border p-4 bg-gray-100">
                <div>
                  مجموع فیصدی:{" "}
                  <span className="bg-amber-500 text-white px-2 py-1 rounded-sm ">
                    {paymentObj.total_payment} دالر
                  </span>
                </div>
                <div>
                  باقی:{" "}
                  <span className="bg-red-400 text-white px-2 py-1 rounded-sm ">
                    {balance.toFixed(2)} دالر
                  </span>
                </div>
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="payment_date" className="w-1/3 md:w-1/4">
                  تاریخ پرداخت<span className="text-red-500">*</span>
                </label>
                <input
                  name="payment_date"
                  type="date"
                  required
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      payment_date: e.target.value,
                    }));
                  }}
                  value={formData.payment_date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>

              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  از حساب<span className="text-red-500">*</span>
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  required
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      account_id: e.value,
                    }));
                  }}
                  value={accounts.find(
                    (row) => row.value === formData.account_id
                  )}
                  options={accounts.filter((row) =>
                    row.use_for.includes("فیصدی خیاط")
                  )}
                />
              </div>

              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="currency_rate" className="w-1/3 md:w-1/4">
                  1 دالر به افغانی <span className="text-red-500">*</span>
                </label>
                <input
                  name="currency_rate"
                  type="text"
                  required
                  onChange={(e) => handleItemChange("currency_rate", e)}
                  value={formData.currency_rate}
                  placeholder="71.00"
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label
                  htmlFor="paid_tailor_fee_amount"
                  className="w-1/3 md:w-1/4"
                >
                  مقدار <span className="text-red-500">*</span>
                </label>
                <input
                  name="paid_tailor_fee_amount"
                  type="text"
                  required
                  onChange={(e) =>
                    handleItemChange("paid_tailor_fee_amount", e)
                  }
                  value={formData.paid_tailor_fee_amount}
                  placeholder="100.00"
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
            </form>
            <hr className="my-8" />
            <div>
              <div className="flex items-center">
                <h2 className="mr-2">اسکن صورت حسابات</h2>
                <input
                  type="file"
                  id="file-upload"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer p-4 rounded-full"
                >
                  <IoMdCloudUpload
                    size={32}
                    className="text-blue-500 hover:text-blue-800"
                  />
                </label>
              </div>
              <hr />
              {previewImage && (
                <div className=" m-4 p-2 w-52 shadow-lg bg-white border border-gray-200 relative">
                  <img
                    src={previewImage}
                    onClick={() => dispatch(setImg(previewImage))}
                    className="bg-black cursor-pointer"
                    alt=""
                  />
                  <div className="flex absolute bottom-1 gap-1">
                    <button
                      type="button"
                      onClick={() => deleteFile()}
                      className=" bg-white p-2 border hover:bg-gray-200  rounded-full shadow-md font-bold"
                    >
                      <FaTrashAlt className="text-red-600 " size={18} />
                    </button>
                    <button
                      type="button"
                      onClick={(e) => handleDownload()}
                      className=" bg-white p-2 border hover:bg-gray-200  rounded-full shadow-md font-bold"
                    >
                      <FaDownload className="text-gray-600" size={18} />
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createSaleForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaCheck className="mt-1 mx-2" />
                )}
                ثبت گردد
              </button>
            </div>
          </div>
          {img && <DocPreview />}
        </div>
      </div>
    </>
  );
};

export default Payment;
