import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Message from "./message";
import { setDelete } from "../redux/features/deleteSlice";
import axios from "../lib/axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Delete = () => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.delete.item);
  const url = useSelector((state) => state.delete.url);
  const navigateUrl = useSelector((state) => state.delete.navigateUrl);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleDelete = async () => {
    setLoading(true);
    const res = await axios.delete(url);
    setLoading(false);

    console.log("reeees", res);
    if (res.status === 201) {
      toast.success("عملیات موفقانه انجام شد.");
      dispatch(setDelete({ item: {}, url: "", navigateUrl: "" }));
      navigate(navigateUrl);
    } else if (res.message) {
      toast.error(res.message);
    } else {
      toast.error("عملیات موفقانه صورت نگرفت لطفن دوباره امتحان کنید.");
    }
  };

  return (
    <>
      <div className="fixed inset-0 opacity-25 bg-black"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              onClick={() =>
                dispatch(setDelete({ item: {}, url: "", navigateUrl: "" }))
              }
              className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="popup-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            <div className="p-4 md:p-5 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                آیا از حذف کردن این ریکارت مطمعن هستید؟
              </h3>
              <button
                data-modal-hide="popup-modal"
                type="button"
                onClick={handleDelete}
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
                disabled={loading ? true : false}
              >
                {loading && (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                )}
                بلی
              </button>
              <button
                data-modal-hide="popup-modal"
                onClick={() =>
                  dispatch(setDelete({ item: {}, url: "", navigateUrl: "" }))
                }
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                نه خیر
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Delete;
