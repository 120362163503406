import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  paymentCreateForm: false,
  paymentEditObj: null,
};

export const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },

    showPaymentCreateForm: (state) => {
      state.paymentCreateForm = !state.paymentCreateForm;
    },
    setPaymentObj: (state, { payload }) => {
      state.paymentEditObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { showCreateForm, showPaymentCreateForm, setPaymentObj } =
  loanSlice.actions;
export default loanSlice.reducer;
