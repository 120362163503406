import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  report: false,
  paymentCreateForm: false,
  paymentEditObj: null,
};

export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    showPaymentCreateForm: (state) => {
      state.paymentCreateForm = !state.paymentCreateForm;
    },
    showReportForm: (state) => {
      state.report = !state.report;
    },
    setPaymentObj: (state, { payload }) => {
      state.paymentEditObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { showReportForm, showPaymentCreateForm, setPaymentObj } =
  expenseSlice.actions;
export default expenseSlice.reducer;
