import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: {},
  url: "",
  navigateUrl: "",
};

export const deleteSlice = createSlice({
  name: "delete",
  initialState,
  reducers: {
    setDelete: (state, { payload }) => {
      state.item = payload.item;
      state.url = payload.url;
      state.navigateUrl = payload.navigateUrl ? payload.navigateUrl : "";
    },
  },
  extraReducers: (builder) => {},
});

export const { setDelete } = deleteSlice.actions;
export default deleteSlice.reducer;
