import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Options from "../../components/lenders/Options";
import { useSelector } from "react-redux";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../components/Delete";

function LenderList() {
  const [lenders, setLenders] = useState({});
  const deleteItem = useSelector((state) => state.delete.item);
  const [categories, setCategories] = useState([
    { name: "همه", active: true, en: "all" },
    { name: "تصویه شده ها", active: false, en: "balance" },
    { name: "طلبکار", active: false, en: "creditor" },
  ]);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState(10);
  const [url, setUrl] = useState(`/api/lenders`);

  useEffect(() => {
    if (!deleteItem.id) {
      getLenders();
    }
  }, [categories, deleteItem, records, url]);
  useEffect(() => {
    let delaySearch;
    const handleSearchDelay = () => {
      delaySearch = setTimeout(() => {
        console.log(`Performing search for: ${search}`);
        getLenders();
      }, 800);
    };
    const handleKeyUp = () => {
      if (delaySearch) {
        clearTimeout(delaySearch);
      }
      handleSearchDelay();
    };
    handleKeyUp();
    return () => {
      clearTimeout(delaySearch);
    };
  }, [search]);

  const getLenders = () => {
    axios
      .post(url, {
        category: categories.find((row) => row.active === true).en,
        search,
        records,
      })
      .then((data) => {
        setLenders(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCategory = (index) => {
    setCategories((prevCategories) => {
      const updatedCategories = prevCategories.map((row, i) => {
        if (index === i) {
          return { ...row, active: true };
        }
        return { ...row, active: false };
      });

      return updatedCategories;
    });
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row bg-slate-50 border justify-between py-2 px-4">
        <h3>لیست قرض دهندگان</h3>
        <div className="flex gap-6 ">
          {categories.map((row, index) => (
            <button
              key={index}
              onClick={() => updateCategory(index)}
              className={` rounded-sm  border border-blue-300 px-2 ${
                row.active
                  ? "bg-blue-300 text-gray-700"
                  : "text-blue-300 hover:text-blue-500 hover:border-blue-500 hover:bg-gray-100"
              }`}
              disabled={row.active ? true : false}
            >
              {row.name}
            </button>
          ))}
          <Link
            to={"/lenders/create"}
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
          >
            <IoIosAddCircle className="ml-1" size={22} />
            اضافه کردن
          </Link>
        </div>
      </div>
      <div className="p-4">
        <div className="flex gap-4 mt-8">
          <select
            onChange={(e) => setRecords(e.target.value)}
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
          >
            <option className="p-0 m-0" value={10}>
              10
            </option>
            <option className="p-0 m-0" value={20}>
              20
            </option>
            <option className="p-0 m-0" value={50}>
              50
            </option>
            <option className="p-0 m-0" value={100}>
              100
            </option>
            <option className="p-0 m-0" value={500}>
              500
            </option>
          </select>
          <input
            type="text"
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
            value={search}
            placeholder="جستجو..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr className="border-y">
              <th className="px-4 py-2 text-start">#</th>
              <th className="px-4 py-2 text-start">نام</th>
              <th className="px-4 py-2 text-start">ایمیل</th>
              <th className="px-4 py-2 text-start">نمبر تماس</th>
              <th className="px-4 py-2 text-start">طلبکار</th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {lenders.data &&
              lenders?.data.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{row.id}</td>
                  <td className="px-4 py-2">{row.name}</td>
                  <td className="px-4 py-2">{row.email}</td>
                  <td className="px-4 py-2">
                    <span dir="ltr">{row.phone_number}</span>
                  </td>
                  <td className="px-4 py-2">
                    {parseFloat(row.creditor).toFixed(3)} دالر
                  </td>
                  <td className="px-4 py-2">
                    <Options lender={row} />{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex gap-4 justify-end p-4">
          {lenders.links &&
            lenders.links.length > 3 &&
            lenders.links.map((row, index) => (
              <button
                disabled={row.active || row.url === null}
                onClick={() => setUrl(row.url)}
                className={`border py-1 px-3 ${
                  row.active ? "bg-blue-500 text-white" : "hover:bg-gray-300"
                }`}
              >
                {row.label}
              </button>
            ))}
          {deleteItem.id && <Delete />}
        </div>
      </div>
    </div>
  );
}

export default LenderList;
