import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balanceSheetForm: false,
  profitAndLossForm: false,
};

export const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  reducers: {
    setBalanceSheetForm: (state) => {
      state.balanceSheetForm = !state.balanceSheetForm;
    },
    setProfitAndLossForm: (state) => {
      state.profitAndLossForm = !state.profitAndLossForm;
    },
  },
  extraReducers: (builder) => {},
});

export const { setBalanceSheetForm, setProfitAndLossForm } =
  accountingSlice.actions;
export default accountingSlice.reducer;
