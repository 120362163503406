import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Options from "../../../components/sales/payments/Options";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../../components/Delete";
import { showPaymentCreateForm } from "../../../redux/features/saleSlice";
import Edit from "../../../components/sales/payments/Edit";

function SPaymentList() {
  const [payments, setPayments] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const paymentCreateForm = useSelector(
    (state) => state.sale.paymentCreateForm
  );
  const paymentEditObj = useSelector((state) => state.sale.paymentEditObj);

  const [search, setSearch] = useState("");
  const [records, setRecords] = useState(10);
  const [url, setUrl] = useState(`/api/sale/payments`);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!deleteItem.id && !paymentCreateForm && !paymentEditObj) {
      getPayments();
    }
  }, [deleteItem, search, records, url, paymentCreateForm, paymentEditObj]);

  const getPayments = () => {
    axios
      .post(url, {
        search,
        records,
      })
      .then((data) => {
        setPayments(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>لیست رسید فروشات</h3>
        <div className="flex gap-6 ">
          <button
            onClick={() => dispatch(showPaymentCreateForm())}
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
          >
            <IoIosAddCircle className="ml-1" size={22} />
            اضافه کردن
          </button>
        </div>
      </div>
      <div className="p-4">
        <div className="flex gap-4 mt-8">
          <select
            onChange={(e) => setRecords(e.target.value)}
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
          >
            <option className="p-0 m-0" value={10}>
              10
            </option>
            <option className="p-0 m-0" value={20}>
              20
            </option>
            <option className="p-0 m-0" value={50}>
              50
            </option>
            <option className="p-0 m-0" value={100}>
              100
            </option>
            <option className="p-0 m-0" value={500}>
              500
            </option>
          </select>

          <input
            type="text"
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
            value={search}
            placeholder="جستجو..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr className="border-y">
              <th className="px-4 py-2 text-start">#</th>
              <th className="px-4 py-2 text-start">تاریخ</th>
              <th className="px-4 py-2 text-start">نمبر فروش</th>
              <th className="px-4 py-2 text-start">مشتری</th>
              <th className="px-4 py-2 text-start">نوت</th>
              <th className="px-4 py-2 text-start">مقدار</th>
              <th className="px-4 py-2 text-start">حساب</th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {payments?.data &&
              payments?.data.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{row.id}</td>
                  <td className="px-4 py-2">{row.date}</td>
                  <td className="px-4 py-2">
                    <Link
                      className="text-blue-500"
                      to={`/sales/${row.bill_id}/edit`}
                    >
                      # {row.bill_id}
                    </Link>
                  </td>
                  <td className="px-4 py-2">{row.name}</td>
                  <td className="px-4 py-2">{row.note}</td>
                  <td className="px-4 py-2">
                    {row.amount} {row.currency}
                  </td>
                  <td className="px-4 py-2">{row.account}</td>
                  <td className="px-4 py-2">
                    <Options payment={row} />{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex gap-4 justify-end p-4">
          {payments.links &&
            payments.links.length > 3 &&
            payments.links.map((row, index) => (
              <button
                key={index}
                disabled={row.active || row.url === null}
                onClick={() => setUrl(row.url)}
                className={`border py-1 px-3 ${
                  row.active ? "bg-blue-500 text-white" : "hover:bg-gray-300"
                }`}
              >
                <>{row.label}</>
              </button>
            ))}

          {paymentEditObj && <Edit />}
          {deleteItem.id && <Delete />}
        </div>
      </div>
    </div>
  );
}

export default SPaymentList;
