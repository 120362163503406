import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Options from "../../components/expenses/Options";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../components/Delete";
import Select from "react-select";

function ExpenseList() {
  const [expenses, setExpenses] = useState({});
  const deleteItem = useSelector((state) => state.delete.item);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState(10);
  const [url, setUrl] = useState(`/api/expenses`);

  const [ccategories, setCcategories] = useState([
    { name: "همه", active: true, en: "all" },
    { name: "تصویه شده ها", active: false, en: "balance" },
    { name: "نا تصویه شده ها", active: false, en: "creditor" },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/api/settings/expense/category/select2")
      .then((data) => {
        setCategories(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (!deleteItem.id) {
      getExpenses();
    }
  }, [deleteItem, records, url, categoryId, ccategories]);
  useEffect(() => {
    let delaySearch;
    const handleSearchDelay = () => {
      delaySearch = setTimeout(() => {
        console.log(`Performing search for: ${search}`);
        getExpenses();
      }, 800);
    };
    const handleKeyUp = () => {
      if (delaySearch) {
        clearTimeout(delaySearch);
      }
      handleSearchDelay();
    };
    handleKeyUp();
    return () => {
      clearTimeout(delaySearch);
    };
  }, [search]);

  const getExpenses = () => {
    axios
      .post(url, {
        category: ccategories.find((row) => row.active === true).en,
        search,
        records,
        categoryId,
      })
      .then((data) => {
        setExpenses(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCategory = (index) => {
    setCcategories((prevCategories) => {
      const updatedCategories = prevCategories.map((row, i) => {
        if (index === i) {
          return { ...row, active: true };
        }
        return { ...row, active: false };
      });

      return updatedCategories;
    });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>لیست مصارف</h3>
        <div className="flex gap-6 ">
          {ccategories.map((row, index) => (
            <button
              key={index}
              onClick={() => updateCategory(index)}
              className={` rounded-sm  border border-blue-300 px-2 ${
                row.active
                  ? "bg-blue-300 text-gray-700"
                  : "text-blue-300 hover:text-blue-500 hover:border-blue-500 hover:bg-gray-100"
              }`}
              disabled={row.active ? true : false}
            >
              {row.name}
            </button>
          ))}
          <Link
            to={"/expenses/create"}
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
          >
            <IoIosAddCircle className="ml-1" size={22} />
            اضافه کردن
          </Link>
        </div>
      </div>
      <div className="p-4">
        <div className="flex gap-4 mt-8">
          <select
            onChange={(e) => setRecords(e.target.value)}
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
          >
            <option className="p-0 m-0" value={10}>
              10
            </option>
            <option className="p-0 m-0" value={20}>
              20
            </option>
            <option className="p-0 m-0" value={50}>
              50
            </option>
            <option className="p-0 m-0" value={100}>
              100
            </option>
            <option className="p-0 m-0" value={500}>
              500
            </option>
          </select>
          <Select
            placeholder="کتگوری"
            required
            onChange={(e) => setCategoryId(e ? e.value : null)}
            options={categories}
            isClearable={true}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "0.2rem", // Adjust the padding value as needed
              }),
            }}
            className="w-60 z-0"
          />
          <input
            type="text"
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
            value={search}
            placeholder="جستجو..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr className="border-y">
              <th className="px-4 py-2 text-start">#</th>
              <th className="px-4 py-2 text-start">کتگوری</th>
              <th className="px-4 py-2 text-start">تاریخ</th>
              <th className="px-4 py-2 text-start">عنوان</th>
              <th className="px-4 py-2 text-start"> مقدار دالر</th>
              <th className="px-4 py-2 text-start"> بیلانس دالر</th>
              <th className="px-4 py-2 text-start">مقدار افغانی</th>
              <th className="px-4 py-2 text-start">برداشت از حساب</th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {expenses.data &&
              expenses?.data.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{row.id}</td>
                  <td className="px-4 py-2">{row.category_name}</td>
                  <td className="px-4 py-2">{row.date}</td>
                  <td className="px-4 py-2">{row.title}</td>
                  <td className="px-4 py-2">
                    {parseFloat(row.amount_usd).toFixed(3)} دالر
                  </td>
                  <td className="px-4 py-2">
                    {parseFloat(row.balance_usd).toFixed(3)} دالر
                  </td>
                  <td className="px-4 py-2">
                    {parseFloat(row.amount_afn).toFixed(3)} افغانی
                  </td>
                  <td className="px-4 py-2">{row.account} </td>
                  <td className="px-4 py-2">
                    <Options expense={row} />{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex gap-4 justify-end p-4">
          {expenses.links &&
            expenses.links.length > 3 &&
            expenses.links.map((row, index) => (
              <button
                disabled={row.active || row.url === null}
                onClick={() => setUrl(row.url)}
                className={`border py-1 px-3 ${
                  row.active ? "bg-blue-500 text-white" : "hover:bg-gray-300"
                }`}
              >
                {row.label}
              </button>
            ))}
          {deleteItem.id && <Delete />}
        </div>
      </div>
    </div>
  );
}

export default ExpenseList;
