import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { setMaterialObj } from "../../redux/features/materialSlice";
import { toEn } from "../../lib/toEn";
import { FaCheck } from "react-icons/fa";

const EditMaterial = () => {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material.editObj);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(material);

  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    getColors();
    getCategories();
  }, [material]);

  const getCategories = () => {
    axios.get(`/api/settings/material/category/select2`).then((data) => {
      setCategories(data);
    });
  };

  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .put(`api/material/${material.id}/update`, { ...formData })
      .then((data) => {
        setLoading(false);
        toast.success("عملیات موفقانه انجام شد.");
        dispatch(setMaterialObj(null));
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleItemChange = (e, index) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,5})?$/;
    if (regex.test(value)) {
      setFormData((prevValue) => ({
        ...prevValue,
        [index]: value,
      }));
    }
  };
  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>ویرایش مواد خام</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => dispatch(setMaterialObj(null))}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createPurchaseForm"
            >
              {categories.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    کتگوری
                    <span className="text-red-500">*</span>
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    required
                    onChange={(e) =>
                      setFormData((prevValue) => ({
                        ...prevValue,
                        material_category_id: e.value,
                      }))
                    }
                    options={categories}
                    value={categories.find(
                      (row) => row.label === formData.material_category_id
                    )}
                  />
                </div>
              )}
              {colors.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    رنگ
                    <span className="text-red-500">*</span>
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    required
                    onChange={(e) =>
                      setFormData((prevValue) => ({
                        ...prevValue,
                        color_id: e.value,
                      }))
                    }
                    options={colors}
                    value={colors.find(
                      (row) => row.label === formData.color_id
                    )}
                  />
                </div>
              )}
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تعداد وارده <span className="text-red-500">*</span>
                </label>

                <input
                  name="total_qtys"
                  type="text"
                  required
                  onChange={(e) => handleItemChange(e, "entry_qtys")}
                  value={formData.entry_qtys}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  مقیاس <span className="text-red-500">*</span>
                </label>
                <input
                  name="unit"
                  type="text"
                  required
                  placeholder="متر"
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      unit: e.target.value,
                    }))
                  }
                  value={formData.unit}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  قیمت فی واحد <span className="text-red-500">*</span>
                </label>
                <input
                  name="unit"
                  required
                  onChange={(e) => handleItemChange(e, "qty_price")}
                  value={formData.qty_price}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createPurchaseForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaCheck className="mt-1 mx-2" />
                )}
                ثبت گردد
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMaterial;
